import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import "./ManageSettings.css";
import { SettingsSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import {
  MASTER_SETTINGS,
  UPDATE_SETTINGS,
} from "../../constants/api-constants";
import ApiServices from "../../services/ApiServices";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";
import { PermissionServiceChild } from "../../constants/permissionService";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";

/*istanbul ignore next */
const ManageSettings = () => {
  const { showLoader } = useLoading();
  const [tabPermissions, setTabPermissions] = useState([]);

  const formik = useFormik({
    initialValues: {
      helpOrSupport: "",
      dirtyHelpOrSupport: false,
      aboutOrLegal: "",
      dirtyAboutOrLegal: false,
      writeToUs: "",
      dirtyWriteToUs: false,
    },
    enableReinitialize: true,
    validationSchema: SettingsSchema,
    dirty: false,
  });

  const getSettings = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(MASTER_SETTINGS);
      if (response.responseStatus?.isSuccess && response.result) {
        formik.setValues({
          ...formik.values,
          helpOrSupport: response.result.find((x) => x.key === "Help_Support")
            ?.value,
          dirtyHelpOrSupport: false,
          aboutOrLegal: response.result.find((x) => x.key === "About_Legal")
            ?.value,
          dirtyAboutOrLegal: false,
          writeToUs: response.result.find((x) => x.key === "Write_To_Us")
            ?.value,
          dirtyWriteToUs: false,
        });
        showLoader(false);
      } else {
        showLoader(false);
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const handleSettingsChange = async (key, value) => {
    try {
      showLoader(true);
      let payload = {
        key: key,
        value: value,
      };
      let response = await ApiServices.httpPut(UPDATE_SETTINGS, payload);
      if (response.responseStatus.isSuccess) {
        formik.setValues({
          ...formik.values,
          dirtyHelpOrSupport:
            key === "Help_Support" ? false : formik.values.dirtyHelpOrSupport,

          dirtyAboutOrLegal:
            key === "About_Legal" ? false : formik.values.dirtyAboutOrLegal,

          dirtyWriteToUs:
            key === "Write_To_Us" ? false : formik.values.dirtyWriteToUs,
        });
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      toast.error(e.InlineMessage);
      showLoader(false);
    }
  };
  useState(() => {
    let moduleDetails = MenuListItems.find(
      (item) => item.name === SystemModulesEnum.SETTINGS
    );
    const currentTabPermissions = PermissionServiceChild(
      moduleDetails.pModuleId,
      SystemModulesEnum.SETTINGS
    );
    console.log("permissions", currentTabPermissions);
    setTabPermissions(currentTabPermissions);
    getSettings();
  });

  return (
    <Grid spacing={2} container className="module-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Settings</h5>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        component={Paper}
        sx={{ backgroundColor: "white" }}
        margin={5}
        paddingBottom={30}
      >
        <Grid
          container
          className="blue-field-section"
          marginLeft={5}
          marginRight={5}
          marginTop={5}
          marginBottom={-25}
        >
          <Grid item xs={12}>
            <Box pr={4} pl={5} pb={2} pt={4}>
              <Typography>
                Help/Support
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </Typography>{" "}
              <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                pt={2}
              >
                <Grid item xs={4} md={4}>
                  <input
                    id="helpOrSupport"
                    name="helpOrSupport"
                    onChange={(e) => {
                      formik.setFieldValue("dirtyHelpOrSupport", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.helpOrSupport}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Web Address"
                    disabled={!tabPermissions?.includes("Update")}
                  />
                  <InlineMessage error={formik.errors.helpOrSupport} />
                </Grid>
                {tabPermissions?.includes("Update") && (
                  <Grid item xs={4} md={4} ml={2}>
                    <Button
                      variant="contained"
                      className="action-button"
                      data-testId="update-details"
                      type="submit"
                      disabled={
                        formik.errors.helpOrSupport ||
                        !formik.values.dirtyHelpOrSupport ||
                        formik.values.helpOrSupport.trim() === ""
                      }
                      onClick={() => {
                        handleSettingsChange(
                          "Help_Support",
                          formik.values.helpOrSupport
                        );
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4} md={4}></Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pr={4} pl={5} pb={2} pt={1}>
              <Typography>
                About/Legal
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                pt={2}
              >
                <Grid item xs={4} md={4}>
                  <input
                    id="aboutOrLegal"
                    name="aboutOrLegal"
                    onChange={(e) => {
                      formik.setFieldValue("dirtyAboutOrLegal", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.aboutOrLegal}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Web Address"
                    disabled={!tabPermissions?.includes("Update")}
                  />
                  <InlineMessage error={formik.errors.aboutOrLegal} />
                </Grid>

                {tabPermissions?.includes("Update") && (
                  <Grid item xs={4} md={4} ml={2}>
                    <Button
                      variant="contained"
                      className="action-button"
                      data-testId="update-details"
                      type="submit"
                      disabled={
                        formik.errors.aboutOrLegal ||
                        !formik.values.dirtyAboutOrLegal ||
                        formik.values.aboutOrLegal.trim() === ""
                      }
                      onClick={() => {
                        handleSettingsChange(
                          "About_Legal",
                          formik.values.aboutOrLegal
                        );
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Grid item xs={4} md={4}></Grid>
          </Grid>
          <Grid item xs={12}>
            <Box pr={4} pl={5} pb={2} pt={1}>
              <Typography>
                Write to Us
                <span style={{ color: "red", marginLeft: "3px" }}>*</span>
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                pt={2}
              >
                <Grid item xs={4} md={4}>
                  <input
                    id="writeToUs"
                    name="writeToUs"
                    onChange={(e) => {
                      formik.setFieldValue("dirtyWriteToUs", true);
                      formik.handleChange(e);
                    }}
                    value={formik.values.writeToUs}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Email Address"
                    disabled={!tabPermissions?.includes("Update")}
                  />
                  <InlineMessage error={formik.errors.writeToUs} />
                </Grid>

                {tabPermissions?.includes("Update") && (
                  <Grid item xs={4} md={4} ml={2}>
                    <Button
                      variant="contained"
                      className="action-button"
                      data-testId="update-details"
                      type="submit"
                      disabled={
                        formik.errors.writeToUs ||
                        !formik.values.dirtyWriteToUs ||
                        formik.values.writeToUs.trim() === ""
                      }
                      onClick={() => {
                        handleSettingsChange(
                          "Write_To_Us",
                          formik.values.writeToUs
                        );
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Grid item xs={4} md={4}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManageSettings;
