import { useEffect, useMemo, useState } from "react";
import "../../../Dashboard/Dashboard.css";
import ApiServices from "../../../../services/ApiServices";
import {
  ADMIN_USER,
  CONTENT,
  CONTENT_FAVORITE,
  CONTENT_STATUS,
} from "../../../../constants/api-constants";
import { toast } from "react-toastify";
import { useLoading } from "../../../../hooks/useLoader";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import DataTable from "../../../Tables/DataTable/DataTable";
import TableColumnModal from "../../../../common/TableColumnModal/TableColumnModal";
import { useHistory } from "react-router";
import {
  ContentPublishStatus,
  DocumentType,
  contentTypeOptions,
  typeOfContentOptions,
} from "../../constants";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { useContentContext } from "../Content.context";
import { Button } from "@mui/material";
import AssociateSubscribers from "../../AssociateSubscribers/AssociateSubscribers";
import { usePageMode } from "../../../../hooks/usePageMode";
import UploadUserModal from "../../../../common/Modal/UploadUserModal";
import { useAuth } from "../../../../hooks/useAuth";
import { SystemUsersEnum } from "../../../../constants/SystemUsers";

const ContentList = (props) => {
  const history = useHistory();
  const page = usePageMode();
  const auth = useAuth();
  const { showLoader } = useLoading();
  const contentContext = useContentContext();
  const { isMassUploadPermission } = props;
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const query = useMemo(() => {
    return props.query;
  }, [props.query]);
  const [userRole, setUserRole] = useState(null);
  const [curentUserId, setCurentUserId] = useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showEditAction, setShowEditAction] = useState(true);
  const [showFavoriteAction, setShowFavoriteAction] = useState(true);
  const [showAssociateSubscriberModal, setShowAssociateSubscriberModal] =
    useState(false);
  const [profileUserId, setProfileUserId] = useState(null);
  const [content, setContent] = useState(null);
  const [action, setAction] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setAction(actionName);
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  // SuperAdmin/Admin and Distributor user can have mass upload access
  // SuperAdmin/Admin only if they have Distributor role, Distributor has by default access -  (only for their own profiles only)

  useEffect(() => {
    if (
      [SystemUsersEnum.ADMIN, SystemUsersEnum.SUPERADMIN].includes(
        auth.getRole()
      )
    ) {
      userDetails();
    }
    ClearSelectionData();
  }, []);

  useEffect(() => {
    setTableColumnModalVisibility(props.showColumnPopup);
  }, [props.showColumnPopup]);

  useEffect(() => {
    getContentList();
    setSelectionColumns();
    if (history.location.pathname.includes("enterprisegroup")) {
      setShowEditAction(false);
      setShowFavoriteAction(false);
    }
  }, [query]);

  useEffect(() => {
    setShowEditAction(
      props.permissions?.includes("Update") &&
        !history.location.pathname.includes("enterprisegroup")
    );
  }, [props.permissions]);

  useEffect(() => {
    page.setIsContentIdsSelected(GetAllSelectedIds("contentId").length > 0);
  }, [adminTable, page]);

  const onEditSuccess = (data) => {};

  const handleChangePageNumber = (event, newPage) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    props.setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  /*istanbul ignore next */
  const userDetails = async () => {
    try {
      const user = await ApiServices.httpGet(ADMIN_USER);
      setUserRole(user?.result?.user?.userTypeName);
      setCurentUserId(user?.result?.user?.userId);
      setUserRoleId(user?.result?.user?.userTypeId);
    } catch (e) {
      console.log(e);
    }
  };

  const onSuccessToggle = (val) => {};
  /*istanbul ignore next */
  const getContentList = async (from = "") => {
    try {
      showLoader(true);
      let firstParam = 0;
      let secondParam = 0;
      ({ firstParam, secondParam } = getParameters(
        auth,
        firstParam,
        props,
        setProfileUserId,
        secondParam
      ));
      const adminData = await ApiServices.httpGet(
        `${CONTENT}/${firstParam}/${secondParam}/${encodeURIComponent(
          JSON.stringify(query)
        )}`
      );
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("contentId");
      if (checkedIds && adminData?.result?.records) {
        onSuccessRecords(adminData, checkedIds, props);
      }
      // ends here
      setAdminTableData(adminData?.result?.records);
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
      showLoader(false);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      props.setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
          PageNumber: 1,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    props.setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
    props.onCancelTableColumnSelection(false);
  };
  /*istanbul ignore next */
  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    } else {
      let newData = { [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(newData));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
    props.onCancelTableColumnSelection(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let contentCols = selectedCols?.contents || [];
    if (contentCols) {
      setSelectedColumns(contentCols);
    }
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setAdminTableData((items) => {
        SelectionFilter(items, "multiple", checked, "contentId");
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setAdminTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };
  /*istanbul ignore next */
  const onFavoriteHandler = async (val, isFavorite) => {
    try {
      const response = await ApiServices.httpPut(CONTENT_FAVORITE, {
        contentId: val.contentId,
        isFavorite: !isFavorite,
      });

      if (response.responseStatus.isSuccess) {
        getContentList();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onDeleteHandler = (e, contentName) => {
    setContent(contentName);
    handleConfirmationOpen(
      "Delete",
      "Are you sure you want to delete this content?"
    );
  };
  /*istanbul ignore next */
  const actionHandler = async (actionName, val, isFavorite) => {
    switch (actionName) {
      case "Add to Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Remove Favourite":
        onFavoriteHandler(val, isFavorite);
        break;
      case "Delete":
        if (val.publishStatus === ContentPublishStatus.DRAFT) {
          setContent(val);
          handleConfirmationOpen(
            "Delete",
            "Are you sure you want to delete this content?"
          );
        }
        break;
      case "Copy":
        props.onEdit(val, DocumentType.CONTENT);
        contentContext.copyContent(val);
        break;
      default:
    }
  };

  const deleteContent = async (e) => {
    try {
      const response = await ApiServices.httpDelete(CONTENT, {
        contentId: content.contentId,
        userId: content.userId,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getContentList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    contentName
  ) => {
    setContent(contentName);
    if (contentName.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the content as Inactive?"
      );
    } else {
      // No confirmation while Activate user
      changeContentStatus(contentName, "", 1);
    }
  };
  /*istanbul ignore next */
  const changeContentStatus = async (contentObj, reason, status) => {
    try {
      const response = await ApiServices.httpPut(CONTENT_STATUS, {
        contentId: contentObj.contentId,
        userId: contentObj.userId,
        status: status,
        reason: reason,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        getContentList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  return (
    <div className="dashboard-main">
      <div
        className="menu-bar"
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <div className="welcome">
          <div className="associate-subscribers">
            {isMassUploadPermission && (
              <UploadUserModal
                from="mass-upload-content"
                userId={profileUserId}
                title={"Upload Matching Excel Sheet"}
                bulkUploadTemplate={"/templates/Content_Mass_Upload.xlsx"}
                getContentList={getContentList}
              />
            )}
            {!props.hideSubscriberAssociationOption && (
              <Button
                disabled={!GetAllSelectedIds("contentId")?.length}
                data-testid="associate-save-button-content"
                className="action-button"
                type="button"
                variant="contained"
                onClick={() => {
                  setShowAssociateSubscriberModal(true);
                }}
              >
                Associate Subscribers
              </Button>
            )}
          </div>

          {showAssociateSubscriberModal && (
            <AssociateSubscribers
              selectedContentIds={GetAllSelectedIds("contentId")}
              clearSelection={() => {
                ClearSelectionData();
                getContentList();
              }}
              setClose={() => {
                setShowAssociateSubscriberModal(false);
              }}
              contentId={props.contentId}
              id={props.id}
            />
          )}
          <DataTable
            uniqueKey="contentId"
            onCheckboxChange={onCheckboxChangeHandler}
            userRoleId={userRoleId}
            columns={selectedColumns}
            currentUserId={curentUserId}
            query={query}
            handleChangePage={handleChangePageNumber}
            success={onSuccessToggle}
            adminTable={adminTable}
            onEditSuccess={onEditSuccess}
            totalPages={totalPages}
            pageSize={pageSize}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            userRole={userRole}
            actionHandler={actionHandler}
            showFavorite={showFavoriteAction}
            showView={true}
            showEdit={showEditAction}
            showDelete={true}
            onDelete={onDeleteHandler}
            onView={props.onView}
            onEdit={props.onEdit}
            onFavorite={onFavoriteHandler}
            onToggle={onToggleHandler}
            page={query.PageNumber}
          />
          {tableColumnModalVisibility && (
            <TableColumnModal
              componentName="contents"
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            showReason={action === "Inactivate"}
            onConfirm={async (e, reason) => {
              if (action === "Delete") {
                deleteContent(e);
              } else if (action === "Activate" || action === "Inactivate") {
                changeContentStatus(content, reason);
              }

              handleConfirmationClose();
            }}
            onCancel={() => {
              handleConfirmationClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentList;
function getParameters(auth, firstParam, props, setProfileUserId, secondParam) {
  if (
    window.location.pathname.includes("distributor") ||
    auth.getRole() === 3
  ) {
    firstParam = props.id;
    setProfileUserId(firstParam);
  } else if (
    window.location.pathname.includes("enterprisegroup") ||
    auth.getRole() === 5
  ) {
    firstParam = -1;
    secondParam = props.id;
    setProfileUserId(secondParam);
  }
  return { firstParam, secondParam };
}

function onSuccessRecords(adminData, checkedIds, props) {
  adminData.result.records = adminData?.result?.records.map((ele) => {
    if (checkedIds.includes(ele.contentId)) {
      ele.isChecked = true;
    } else {
      ele.isChecked = false;
    }
    ele.typeOfContentName =
      typeOfContentOptions.find((item) => item.value === ele.typeOfContent)
        ?.name || "";

    ele.contentTypeName =
      contentTypeOptions.find((item) => item.value === ele.contentType)?.name ||
      "";

    ele.tages = ele.contentTagList?.flatMap((item) => item.tag).join(", ");

    return ele;
  });
  if (adminData.result.records.length > 0) {
    props.setContentOriginators(
      adminData.result.records[0].contentFilters?.contentOriginators || []
    );
  }
}
