import { COLUMN_TYPES } from "../../constants/common";

const ActionColumnsAdmin = [
  {
    fieldName: "action",
    label: "",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.ACTION,
    showInSelection: false,
    width: 190,
    rightPadding: 0,
    isFixed: true,
  },
];

export const DefaultRoleColumns = [
  {
    exportLabel: "Role Name",
    fieldName: "name",
    label: "Role Name",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },

  {
    exportLabel: "Appliable For",
    fieldName: "roleTypes",
    label: "Applicable For",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
  },

  {
    exportLabel: "Status",
    fieldName: "status",
    label: "Status",
    sort: true,
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
  },

  ...ActionColumnsAdmin,
];

export const DefaultApprovalColumns = [
  {
    exportLabel: "Brand Name",
    fieldName: "brandName",
    label: "Brand Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  {
    exportLabel: "Requester Name",
    fieldName: "requesterName",
    label: "Requester Name",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  {
    exportLabel: "First Name",
    fieldName: "firstName",
    label: "First Name",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  {
    exportLabel: "Last Name",
    fieldName: "lastName",
    label: "Last Name",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  {
    fieldName: "roles",
    label: "Roles",
    exportLabel: "Roles",
    sort: false,
    isSelected: true,
    type: COLUMN_TYPES.MULTI_VALUE,
    showInSelection: true,
    width: 200,
  },
  {
    exportLabel: "Request Type",
    fieldName: "approvalType",
    label: "Request Type",
    isSelected: true,
    type: COLUMN_TYPES.ENUM,
    showInSelection: true,
    sort: true,
    width: 200,
    mapping: {
      1: "Association Request",
      2: "New Registration",
    },
  },
  {
    exportLabel: "Received Date",
    fieldName: "createdOn",
    label: "Received Date",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    sort: true,
    width: 200,
  },
  {
    exportLabel: "Action Date",
    fieldName: "updatedOn",
    label: "Action Date",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
    sort: true,
    width: 200,
  },
  {
    exportLabel: "Status",
    fieldName: "statusText",
    label: "Status",
    isSelected: true,
    type: COLUMN_TYPES.STYLED_BASIC,
    showInSelection: true,
    sort: true,
    width: 150,
  },
  ...ActionColumnsAdmin,
];
export const DefaultApprovalAssociatedColumns = [
  {
    fieldName: "associateSubId",
    label: "Id",
    exportLabel: "Id",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: false,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    exportLabel: "FirstName",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    exportLabel: "LastName",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "email",
    label: "Email",
    exportLabel: "Email",
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
  },
  {
    fieldName: "status",
    label: "Associated",
    exportLabel: "Status",
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
  },
  {
    fieldName: "activitystatus",
    label: "Status",
    exportLabel: "Status",
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    showInSelection: true,
  },
];

export const ManageSubscriberColumns = [
  {
    fieldName: "userId",
    label: "USER ID",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "email",
    label: "Email Address",
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
  },
  {
    fieldName: "associateFrom",
    label: "From",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
  },
  {
    fieldName: "associateTo",
    label: "To",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
  },
  {
    fieldName: "contentSubscriberStatus",
    label: "Associated",
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    showInSelection: true,
    activeLabelText: "Yes",
    inactiveLabelText: "No",
  },
  {
    fieldName: "status",
    label: "Status",
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    showInSelection: true,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    cssClass: "status-change",
  },
  {
    fieldName: "egBrandName",
    label: "Enterprise Group",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },

  ...ActionColumnsAdmin,
];

export const ManageSubscriberCatalogBundleColumns = [
  {
    fieldName: "userId",
    label: "USER ID",
    isSelected: false,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "firstName",
    label: "First Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  {
    fieldName: "email",
    label: "Email Address",
    isSelected: true,
    type: COLUMN_TYPES.EMAIL,
    showInSelection: true,
  },
  {
    fieldName: "associateFrom",
    label: "From",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
  },
  {
    fieldName: "associateTo",
    label: "To",
    isSelected: true,
    type: COLUMN_TYPES.DATE,
    showInSelection: true,
  },
  {
    fieldName: "contentSubscriberStatus",
    label: "Associated",
    isSelected: true,
    type: COLUMN_TYPES.STATUS_CHANGE,
    showInSelection: true,
    activeLabelText: "Yes",
    inactiveLabelText: "No",
  },
  {
    fieldName: "status",
    label: "Status",
    isSelected: true,
    type: COLUMN_TYPES.ACTIVE_INACTIVE_LABEL,
    showInSelection: true,
    activeLabelText: "Active",
    inactiveLabelText: "Inactive",
    cssClass: "status-change",
  },
  {
    fieldName: "egBrandName",
    label: "Enterprise Group",
    isSelected: true,
    type: COLUMN_TYPES.BASIC,
    showInSelection: true,
  },
  ...ActionColumnsAdmin,
];
