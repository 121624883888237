import React from "react";
import { number } from "yup";
import { ARGB_To_RGBA_Color } from "../constants";

Line.propTypes = {
  top: number,
  left: number,
  height: number,
  width: number,
};

function Line(props) {
  const { line, fType } = props;
  const whenXNotEqual =
    line.X1 > line.X2 ? line.X1 - line.X2 : line.X2 - line.X1;
  const width = line.X1 === line.X2 ? line.StrokeThickness : whenXNotEqual;
  const whenYNotEqual =
    line.Y1 > line.Y2 ? line.Y1 - line.Y2 : line.Y2 - line.Y1;
  const height = line.Y1 === line.Y2 ? line.StrokeThickness : whenYNotEqual;
  return !(width === height && height === line.StrokeThickness) ? (
    <div
      style={{
        position: "absolute",
        top: `${line.Y1}px`,
        left: props.isBgImage ? `${line.X1}px` : `${line.X1 + 10}px`,
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor:
          fType === "NonAcord" ? ARGB_To_RGBA_Color(line.Stroke) : line.Stroke,
      }}
    ></div>
  ) : null;
}

export default Line;
