export const SystemUsers = {
  1: "Super Admin",
  2: "Admin",
  3: "Distributor",
  4: "Enterprise Group User",
  5: "Distributor Internal User",
  6: "Enterprise Group Internal User",
  7: "Subscriber",
};

export const SystemUsersEnum = {
  SUPERADMIN: 1,
  ADMIN: 2,
  DISTRIBUTOR: 3,
  ENTERPRISE: 4,
  DISTRIBUTOR_INTERNAL: 5,
  ENTERPRISE_INTERNAL: 6,
  SUBSCRIBER: 7,
};

export const SystemModulesEnum = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  DISTRIBUTORS: "Distributors",
  DISTRIBUTOR_PROFILE_BASIC_DETAILS: "Distributor Basic Details",
  DISTRIBUTOR_PROFILE_ASSOCIATION_DETAILS: "Distributor Association Details",
  DISTRIBUTOR_PROFILE_SUBSCRIPTION_DETAILS: "Distributor Subscription Details",
  DISTRIBUTOR_INTERNAL_USER: "Distributor Internal User",
  DISTRIBUTOR_CONTENT: "Distributor Content",
  DISTRIBUTOR_CATALOG: "Distributor Catalog",
  DISTRIBUTOR_BUNDLE: "Distributor Bundle",
  ENTERPRISE_GROUPS: "Enterprise Groups",
  ENTERPRISE_PROFILE_BASIC_DETAILS: "Enterprise Basic Details",
  ENTERPRISE_PROFILE_ASSOCIATION_DETAILS: "Distributor & Publisher Management",
  ENTERPRISE_INTERNAL_USER: "Enterprise Internal User",
  ENTERPRISE_CONTENT: "Enterprise Content",
  ENTERPRISE_CATALOG: "Enterprise Catalog",
  SUBSCRIBERS: "Subscribers",
  ENTERPRISE_BUNDLE: "Enterprise Bundle",
  APPROVAL: "Approval",
  CONTENT: "Content",
  CATALOG: "Catalog",
  BUNDLE: "Bundle",
  MODULE_MANAGEMENT: "Module Management",
  REPORTS: "Reports",
  ROLE_MANAGEMENT: "Role Management",
  USERS: "Users",
  MANAGE_DOCUMENTS: "Manage Documents",
  SETTINGS: "Settings",
};

export const FrequencyEnum = {
  1: { name: "Monthly", count: 1 },
  2: { name: "Quarterly", count: 3 },
  3: { name: "Semi-Annual", count: 6 },
  4: { name: "Annual", count: 12 },
};
