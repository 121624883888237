import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Checkbox,
  IconButton,
  FormControlLabel,
  Button,
  Box,
  Modal,
  List,
  ListSubheader,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./CreateDistributorModal.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import MultiSelectDropDown from "../../MultiSelectDropDown/MultiSelectDropDown";
import PhoneNumberTextbox from "../../PhoneNumberTextbox/PhoneNumberTextbox";
import BusinessPhoneNumberTextbox from "../../PhoneNumberTextbox/BusinessPhoneNumberTextbox";
import { useHistory } from "react-router";
import { usePageMode } from "../../../hooks/usePageMode";
import { useMasterData } from "../../../hooks/useMasterData";
import InlineMessage from "../../InlineMessage/InlineMessage";
import { getServiceInstance } from "../../../services/UserServices/UserService";
import { useLoading } from "../../../hooks/useLoader";
import SearchableDropDown from "../../SearchableDropDown/SearchableDropdown";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material/";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { TABS } from "../../../constants/common";
import { MandatoryField } from "../../MandatoryFieldsIcon/MandatoryField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiServices from "../../../services/ApiServices";
import {
  PARENT_DISTRIBUTOR,
  SUBSCRIBER_ADD_ON,
  UPLOAD_LOGO,
  SUBSCRIBER_RENEWAL_HISTORY,
  ENTERPRISE_GROUP_USER,
} from "../../../constants/api-constants";
import filterRoles from "../../../utilities/filterRoles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SystemUsersEnum } from "../../../constants/SystemUsers";
import CurrencyTextbox from "../../../common/CurrencyTextbox/CurrencyTextbox";
import Confirmation from "../../Confirmation/Confirmation";
import {
  UserAddOnType,
  brandLogoAcceptedFormats,
  deleteSubscriptionConfirmationMessage,
  subscriptionStatusFilters,
} from "../../../screens/Distributors/constants";
import cloneDeep from "lodash/cloneDeep";
import FooterButtons from "../../FooterButtons/FooterButtons";
import { PermissionEnum } from "../../../constants/permissionService";
import FileUploader from "../../FileUploader/FileUploader";
import {
  fetchImageAsBase64,
  getFileExtension,
  getPhoneCode,
} from "../../../utilities/common";
import MultiSelectCheckboxSearchableDropdown from "../MultiSelectCheckboxSearchableDropdown/MultiSelectCheckboxSearchableDropdown";
import { SubscriberRenewalHistoryColumns } from "../../../common/TableColumnModal/DefaultDataColumns";
import DataTable from "../../../screens/Tables/DataTable/DataTable";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CheckboxList from "../../../common/CheckboxList/CheckboxList";
import Popover from "@mui/material/Popover";
import Group2252459 from "../../../common/Modal/Distributor/Group2252459.png";
import { AssociationStatusEnum } from "../AssociationStatusEnum";
import Group2252519 from "../../../common/Modal/Distributor/Group2252519.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

/*istanbul ignore next */
const CreateDistributorModal = (props) => {
  const {
    basicDetailPermission,
    associationDetailsPermission,
    subscriptionDetailsPermission,
  } = props;
  const [parentDropdown, setParentDropdown] = useState([]);
  const { permissions } = props;
  const history = useHistory();
  const auth = useAuth();
  const page = usePageMode();
  const { showLoader } = useLoading();
  const permissionId = auth.getPermissionId();
  const frequencyOptions = [
    { label: "Monthly", value: 1 },
    { label: "Quarterly", value: 2 },
    { label: "Semi-Annual", value: 3 },
    { label: "Annual", value: 4 },
  ];
  const FrequencyType = {
    1: "Monthly",
    2: "Quarterly",
    3: "Semi-Annual",
    4: "Annual",
  };

  const AllFrequencyType = {
    99: "One Time Cost",
    ...FrequencyType,
  };

  const ContentTypeEnum = {
    1: "Content",
    2: "Catalog",
    3: "Bundle",
  };

  const userService = getServiceInstance(props.routeData?.userTypeId);
  const currentUserId = auth.getCurrentUser()?.userId;
  const currentUserBrandName = auth.getCurrentUser()?.brandName;

  const masterDataContext = useMasterData();
  let hidden = true;
  const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
  const maxSizeExeedError =
    "File size exceeds maximum 2MB allowed. Kindly reduce the size of the file before proceeding"; // 2MB Exeeded

  useEffect(() => {
    if (props.formData.userId > 0) {
      formik.setValues(props.formData);
      let data = parentDropdown.filter(
        (x) => x.userId === props.formData.parentUser?.parentUserId
      );

      setSelectedValue(
        data.map((res) => {
          return {
            label: res.name,
            id: res.userId,
          };
        })
      );
      setMembership(
        props.formData.parentUser?.parentUserId
          ? `INCHANTED_${props.formData.parentUser?.parentUserId}`
          : ""
      );
      setRoles(props.formData.roles);
    } else {
      formik.validateForm();
      if (localStorage.getItem("DefaultSubscriberEmail")) {
        formik.setFieldValue(
          "primaryEmail",
          localStorage.getItem("DefaultSubscriberEmail")
        );
      }
    }
  }, [props.formData, props.formData.userId, parentDropdown]);

  const [viewForm, setViewForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [type, setType] = useState("");
  const [showAnotherNumber, setShowAnotherNumber] = useState(false);
  const [showAnotherMobile, setShowAnotherMobile] = useState(false);
  const [showAnotherFax, setShowAnotherFax] = useState(false);
  const [distributorTypeOptions, setDistributorTypeOptions] = useState([]);
  const [annualRevenues, setAnnualRevenues] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [career, setCareer] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [carrier, setCarrier] = useState([]);
  const [associationPopupConfirmation, setAssociationPopupConfirmation] =
    useState(false);
  const [technology, setTechnology] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(
    (props.formData.status > 0 ||
      props.routeData?.userTypeId !== SystemUsersEnum.DISTRIBUTOR) &&
      props.isEditMode
  );
  const [enterpriseTypes, setEnterpriseTypes] = useState([]);
  const [distributorTypes, setDistributorTypes] = useState([]);
  const [memberShip, setMembership] = useState(null);
  const [logo, setLogo] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [entUserDists, setEntUserDists] = useState([]);

  const [renewalHistoryQuery, setRenewalHistoryQuery] = useState({
    searchText: "",
    SortType: "asc",
    SortColumn: "startDate",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      SubscriberId: 0,
      SubscriptionId: 0,
      IsEgSubscription: false,
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedFilter, setExpandedFilter] = useState("NA");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedFreq, setSelectedFreq] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    status: [1, 4],
    frequency: [1, 2, 3, 4],
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilterHandler = () => {
    setFilterQuery({
      status: [1, 4],
      frequency: [1, 2, 3, 4],
    });
    setSelectedStatus([]);
    setSelectedFreq([]);
  };
  const applyFilterHandler = () => {
    setFilterQuery((prevState) => {
      return {
        ...prevState,
        status: selectedStatus.length > 0 ? selectedStatus : [1, 4],
        frequency: selectedFreq.length > 0 ? selectedFreq : [1, 2, 3, 4],
      };
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : "NA");
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const onFilterFreqChangeHandler = (values) => {
    setSelectedFreq(values);
  };

  const formik = useFormik({
    initialValues: props.formData,
    enableReinitialize: true,
    validationSchema: props?.routeData?.schema,
  });

  const populateAssociatedDistBrandName = async () => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${ENTERPRISE_GROUP_USER}/${currentUserId}`
      );
      if (response.responseStatus.isSuccess) {
        let approvedDistList = response.result.associateDistributors;
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let filteredList = approvedDistList.filter(
          (item) =>
            !(
              item.isStopped ||
              (item.expirationDate &&
                moment(item.expirationDate).format("YYYY-MM-DD") <
                  moment(today).format("YYYY-MM-DD"))
            ) &&
            !(
              moment(item.startDate).format("YYYY-MM-DD") >
              moment(today).format("YYYY-MM-DD")
            ) &&
            item.associatonStatus === AssociationStatusEnum.Approved
        );

        let finalList = [
          ...new Map(
            filteredList.map((item) => [item["distributorUserId"], item])
          ).values(),
        ];

        setEntUserDists(finalList);
      } else {
        toast.success(response.responseStatus.error);
      }
      showLoader(false);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  useState(() => {
    if (auth.getRole() === SystemUsersEnum.ENTERPRISE) {
      populateAssociatedDistBrandName();
    }
  });

  useEffect(() => {
    page.setDirty(formik.values.dirty);
  }, [formik.values.dirty]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("DefaultSubscriberEmail");
    };
  }, []);

  useEffect(() => {
    if (props.formData.userId > 0) {
      formik.setValues(props.formData);
      setRoles(props.formData.roles);
      populateDropdownParent();
    }
  }, [props.formData, props.formData.userId]);

  useEffect(() => {
    setIsEditMode((prevState) => {
      return props.formData.status > 0 && prevState;
    });
  }, [props.formData.status]);

  useEffect(() => {
    if (
      props.formData.enterpriseIds ||
      (props.formData.distributorIds && enterpriseTypes.allEnterpriseUsers) ||
      distributorTypes.allDistributorUsers
    ) {
      getValueForDistributorId(props.formData.distributorIds);
      getValueForEnterpriseGroupId(props.formData.enterpriseIds);
    }
  }, [
    props.formData.enterpriseIds,
    props.formData.distributorIds,
    enterpriseTypes.allEnterpriseUsers,
    distributorTypes.allDistributorUsers,
  ]);

  const [expanded, setExpanded] = React.useState("panel1");
  const [permissionList, setPermissionList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const options = allRoles && allRoles.map((item) => item.name);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options && options.length > 0 && selected.length === options.length;
  const [subscriberRenewalHistoryList, setSubscriberRenewalHistoryList] =
    useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePageNumber = (event, newPage) => {
    setCurrentPage(newPage);
    setRenewalHistoryQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setRenewalHistoryQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleChangeDropDown = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue(
        "roles",
        selected.length === options.length ? [] : options
      );
      return;
    }
    setSelected(value);
    formik.setFieldValue("roles", value);
  };

  const setRoles = (rolesData) => {
    if (rolesData) {
      const userData = rolesData.map((item) => item.name);
      setSelected(userData);
    }
  };

  useEffect(() => {
    setMasterData();
    getDistributorDropDownDataList();
    populateDropdownParent();
  }, []);

  const getDistributorDropDownDataList = async () => {
    try {
      const data = masterDataContext.masterDataValue;
      setPermissionList(data.result.parentPermissions);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const populateDropdownParent = async () => {
    try {
      const data = await ApiServices.httpGet(`${PARENT_DISTRIBUTOR}`);
      const result = data.result.filter(
        (res) => res.userId !== props.formData.userId
      );
      setParentDropdown(result);
    } catch {
      toast.error("Internal Server Error");
    }
  };

  const getRenewalHistory = async (item) => {
    try {
      showLoader(true);
      let queryState = { ...renewalHistoryQuery };
      if (item) {
        queryState.filter.SubscriptionId = item.requestId;
        queryState.filter.SubscriberId = formik.values.userId;
      }
      setRenewalHistoryQuery(queryState);
      let response = await ApiServices.httpGet(
        `${SUBSCRIBER_RENEWAL_HISTORY}?query=${JSON.stringify(queryState)}`
      );
      if (response.responseStatus.isSuccess) {
        let subRenewalList = response.result.records.map((x) => {
          return {
            brandName: x.brandName,
            subscriptionName: x.subscriptionName,
            frequencyText: FrequencyType[x.frequencyType],
            isRecurring: x.isRecurring,
            subscriptionFees: x.subscriptionFees,
            startDate: x.startDate,
            endDate: x.endDate,
          };
        });
        setSubscriberRenewalHistoryList(subRenewalList);
        setTotalPages(response?.result?.totalPages);
        setPageSize(response?.result?.pageSize);
        setCurrentPage(response?.result?.currentPage);
      } else {
        toast.success(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (
      formik.values.distributorType?.filter(
        (e) => e.label === "Agents / Broker"
      ).length === 1
    ) {
      setType("all");
    } else if (
      formik.values.distributorType?.filter(
        (e) => e.label === "Agents / Broker"
      ).length === 1 &&
      formik.values.distributorType?.length === 1
    ) {
      setType("agent");
    } else setType("other");
  }, [formik.values.distributorType]);

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
  };

  const handleEditModalCancelClick = (item, subItem) => {
    setOpenConfirmation(true);
    setSelectedItem(item.uid);
    setSelectedSubItem(subItem.uniqueId);
  };

  const handleFieldChange = (fieldName, e) => {
    if (fieldName === "firstName") {
      formik.setFieldValue("dirty", true);
      formik.setFieldValue("billingContact", e.target.value);
    }
    formik.handleChange(e);
    formik.setFieldValue(fieldName, e.target.value);
  };
  const handleFieldChanges = (fieldName, e) => {
    if (fieldName === "lastName") {
      formik.setFieldValue("dirty", true);
      formik.setFieldValue("billingContactLastName", e.target.value);
    }
    formik.handleChange(e);
    formik.setFieldValue(fieldName, e.target.value);
  };

  const renderConfirmationPopup = () => {
    return (
      <Modal
        open={associationPopupConfirmation}
        onClose={() => {
          handleChangePageNumber("", 1);
          setAssociationPopupConfirmation(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            onClick={() => {
              handleChangePageNumber("", 1);
              setAssociationPopupConfirmation(false);
            }}
            style={{
              cursor: "pointer",
              justifyContent: "end",
              display: "flex",
              marginTop: "5px",
            }}
          >
            <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{
              mb: 2,
              mt: -2,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Renewal History
          </Typography>

          <div>
            <DataTable
              checkboxesNotRequired={true}
              serachBarNotRequired={true}
              columns={SubscriberRenewalHistoryColumns}
              adminTable={subscriberRenewalHistoryList}
              query={renewalHistoryQuery}
              totalPages={totalPages}
              pageSize={pageSize}
              page={currentPage}
              handleChangePage={handleChangePageNumber}
              onPageSizeChange={pageSizeChangeHandler}
            />
          </div>
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    populateStateOptions(formik.values.countryId || 1);
  }, [formik.values.countryId, allStateOptions]);

  const setMasterData = async () => {
    try {
      showLoader(true);
      const response = await userService.populateDropdownOptions();
      showLoader(false);
      let allRolesList = filterRoles(
        props.routeData.userTypeId,
        response.roles
      );
      setAllRoles(allRolesList);
      if (props?.routeData?.userTypeId === SystemUsersEnum.SUBSCRIBER) {
        setSelected(allRolesList.map((item) => item.name));
      }
      setDistributorTypeOptions(response.userTypeOptions);
      setCountryOptions(response.countries);
      setAllStateOptions(response.states);
      setAnnualRevenues(response.annualRevenues);
      setAreaOptions(response.areas);
      setCareer(response.carrierRepresents);
      setIndustry(response.industryGroups);
      setCarrier(response.carriers);
      setTechnology(response.technologies);
      if (auth.getRole() === 1 || auth.getRole() === 2) {
        let allEnterpriseUsers = await userService.populateEnterpriseDropdown();
        setEnterpriseTypes(allEnterpriseUsers);
        let allDistributorUsers =
          await userService.populateDistributorDropdown();
        setDistributorTypes(allDistributorUsers);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const permissionChange = (index, id, column) => {
    let arr = formik.values;
    if (arr.parentUser.permissions[index]) {
      arr.parentUser.permissions[index][column] =
        !arr.parentUser.permissions[index][column];
      arr.parentUser.permissions[index]["parentPermissionMasterId"] = id;
    } else {
      arr.parentUser.permissions[index] = {
        parentUserPermissionId: 0,
        userId: 0,
        parentUserId: 0,
        parentPermissionMasterId: id,
        isRead: column === "isRead" ? true : false,
        isWrite: column === "isWrite" ? true : false,
        isNoAccess: column === "isNoAccess" ? true : false,
      };
    }
  };

  const createDistributor = async (isNext) => {
    try {
      showLoader(true);
      let payload = cloneDeep(formik.values);
      reversePayloadSubs(payload);
      if (validateSubscriptions(payload.distributorSubscriptionDomains)) {
        toast.error(
          "Subscription Name already exists. Please choose a different name."
        );
        showLoader(false);
        return;
      }
      setRolesMethod(allRoles, selected, payload);

      formattingPayload(payload, props, auth, currentUserId);
      const response = await userService.createUser(payload);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        createUserSuccess(
          page,
          response,
          setIsEditMode,
          props,
          executeScroll,
          isNext,
          history
        );
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      console.log("error", event);
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const updateDistributor = async (isNext) => {
    try {
      showLoader(true);
      page.setDirty(false);
      let payload = cloneDeep(formik.values);
      reversePayloadSubs(payload);
      if (validateSubscriptions(payload.distributorSubscriptionDomains)) {
        toast.error(
          "Subscription Name already exists. Please choose a different name."
        );
        showLoader(false);
        return;
      }
      let roles = [];
      if (formik.values.userId) {
        roles = props.formData.roles;
      } else {
        roles = allRoles.filter((element) => selected.includes(element.name));
      }
      payload.roles = roles;
      if (
        formik.values.logoGcpFileUrl &&
        auth.getCurrentUser().userId === formik.values.userId
      ) {
        await fetchImageAsBase64(formik.values.logoGcpFileUrl).then((data) => {
          auth.setBrandImage(data);
        });
      } else {
        auth.setBrandImage(null);
      }
      formattingPayload(payload, props, auth, currentUserId);
      const response = await userService.updateUser(payload);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        formik.setFieldValue(
          "distributorSubscriptionDomains",
          formik.values.distributorSubscriptionDomains
        );
        page.setEditMode(false);
        setIsEditMode(false);
        setEditMode(false);
        props.onNext(isNext, response.result);
        toast.success(response.responseStatus.description);
        executeScroll();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  const updateSubscriberAddOn = async () => {
    showLoader(true);
    try {
      const data = await ApiServices.httpPost(
        SUBSCRIBER_ADD_ON,
        formik.values.userAddOns
      );
      showLoader(false);
      setPermissionList(data.result.parentPermissions);
      if (data?.result[0]?.isActive) {
        toast.success(data.responseStatus.description);
        page.setEditMode(false);
        setIsEditMode(page.isEditMode);
      } else if (data?.result[0]?.isActive === false) {
        page.setEditMode(false);
        setIsEditMode(page.isEditMode);
        toast.success(data.responseStatus.description);
      } else {
        toast.error(data.responseStatus.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onChangeEnterpriseGroupId = (newValue) => {
    page.setDirty(true);
    formik.setFieldValue("enterpriseIds", newValue);
  };

  const onChangeDistributorUserId = (newValue) => {
    page.setDirty(true);
    formik.setFieldValue("distributorIds", newValue);
  };

  const handleCancelClick = () => {
    history.push(props.routeData?.listRoute);
  };

  const handleChangeValue = (e, which) => {
    try {
      if (which === "subscriptionId") {
        formik.setFieldValue(`subscription.${which}`, e.target.value);
      } else {
        let d = e && e._d ? e._d : "";
        formik.setFieldValue(
          `subscription.${which}`,
          moment(d).format("YYYY-MM-DD")
        );
      }
    } catch (error) {
      console.log("date error", error);
    }
  };
  const handleChangeValueegSubscription = (e, which, index) => {
    try {
      let d = e && e._d ? e._d : "";
      formik.setFieldValue(
        `egSubscriptions[${index}].${which}`,
        moment(d).format("YYYY-MM-DD")
      );
    } catch (error) {
      console.log("date error", error);
    }
  };

  const getValueForEnterpriseGroupId = (entIds) => {
    let retVal = [];
    if (entIds?.length) {
      entIds.forEach((element) => {
        enterpriseTypes?.allEnterpriseUsers?.forEach((item) => {
          if (element === item.value) retVal.push(item);
        });
      });
    }
    formik.setFieldValue("enterpriseIds", retVal);
  };

  const getValueForDistributorId = (distIds) => {
    let retVal = [];
    if (distIds?.length) {
      distIds.forEach((element) => {
        distributorTypes?.allDistributorUsers?.forEach((item) => {
          if (element === item.value) retVal.push(item);
        });
      });
    }

    formik.setFieldValue("distributorIds", retVal);
  };

  const myRef = useRef(null);
  const executeScroll = () =>
    myRef?.current?.firstChild?.scrollIntoView({
      behavior: "smooth",
    });

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const renderSubscriptionDetails = (item, index) => {
    return (
      <div>
        <Grid container spacing={0} className="blue-field-section">
          <Grid item xs={12} p={1}>
            {item.isEgSubscription ? (
              <Grid container>
                <Grid
                  item
                  xs={1.3}
                  md={1.3}
                  p={1}
                  className="blue-field-section"
                >
                  <label
                    htmlFor="subscriptionName"
                    style={{
                      width: "129px",
                      lineBreak: "anywhere",
                      marginTop: "-16px",
                      marginBottom: "0px",
                    }}
                  >
                    Enterprise Group Brand Name
                  </label>
                  <input
                    id="subscriptionName"
                    data-testid={"subscription-dropdown"}
                    name="subscriptionName"
                    value={formik.values?.egSubscriptions[index]?.brandName}
                    className="form-control form-select"
                    disabled
                  />
                </Grid>

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.3}
                    md={1.3}
                    p={1}
                    className="blue-field-section"
                  >
                    <label
                      htmlFor="subscription"
                      style={{
                        width: "129px",
                        lineBreak: "anywhere",
                        marginTop: "-16px",
                        marginBottom: "0px",
                      }}
                    >
                      Distributor Brand Name
                    </label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        formik.values?.egSubscriptions[index]?.distBrandName
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.4}
                    md={1.4}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="subscription">Subscription Type</label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        formik.values?.egSubscriptions[index]?.subscriptionName
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="frequencyType">Frequency</label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        AllFrequencyType[
                          formik.values?.egSubscriptions[index]?.frequencyType
                        ]
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.5}
                    md={1.5}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="associationId">Auto Renewal</label>
                    <FormControlLabel
                      className={
                        !isEditMode
                          ? "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm border-cus-cus-disabled"
                          : "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm"
                      }
                      label={<Typography>Auto Renewal</Typography>}
                      checked={
                        formik.values.egSubscriptions[index]?.isRecurring
                      }
                      name="Available Public"
                      data-testid="preview-available-checkbox"
                      control={<Checkbox disabled />}
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.6}
                    md={1.6}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="associationId">Subscription Amount</label>
                    <CurrencyTextbox
                      id="associationId"
                      name="associationId"
                      value={formik.values?.egSubscriptions[
                        index
                      ]?.subscriptionFees.toFixed(2)}
                      disabled
                      type="number"
                    />
                  </Grid>
                )}

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section date-picker-matui desktop-date-picker"
                  >
                    <label htmlFor="startDate">Start Date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control date-field"
                        inputFormat="MM/DD/YYYY"
                        value={
                          formik.values?.egSubscriptions[index]?.startDate ||
                          null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          handleChangeValueegSubscription(
                            e,
                            "startDate",
                            index
                          );
                        }}
                        renderInput={(params) => (
                          <TextField id="startDate" {...params} />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                    {formik.errors?.egSubscriptions?.length > 0 &&
                      index < formik.errors?.egSubscriptions?.length && (
                        <InlineMessage
                          error={
                            formik.errors?.egSubscriptions[index]?.startDate
                          }
                        />
                      )}
                  </Grid>
                )}

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section date-picker-matui desktop-date-picker"
                  >
                    <label htmlFor="endDate"> End Date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control date-field"
                        inputFormat="MM/DD/YYYY"
                        value={
                          formik.values?.egSubscriptions[index]?.endDate || null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          handleChangeValueegSubscription(e, "endDate", index);
                        }}
                        renderInput={(params) => (
                          <TextField id="endDate" {...params} />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                    {formik.errors?.egSubscriptions?.length > 0 &&
                      index < formik.errors?.egSubscriptions?.length && (
                        <InlineMessage
                          error={formik.errors?.egSubscriptions[index]?.endDate}
                        />
                      )}
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.3}
                    md={1.3}
                    p={1}
                    className=" blue-field-section"
                  >
                    <Button
                      style={{ marginTop: "20px" }}
                      onClick={() => {
                        renewalhistory(formik.values?.egSubscriptions[index]);
                      }}
                    >
                      Renewal History
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={1.3}
                  md={1.3}
                  p={1}
                  className="blue-field-section"
                >
                  <label
                    htmlFor="subscriptionName"
                    style={{
                      width: "129px",
                      lineBreak: "anywhere",
                      marginTop: "-26px",
                    }}
                  >
                    Content/Catalog/Bundle Name
                  </label>
                  <input
                    id="subscriptionName"
                    data-testid={"subscription-dropdown"}
                    name="subscriptionName"
                    value={
                      formik.values?.egSubscriptions[index]?.subscriptionName
                    }
                    className="form-control form-select"
                    disabled
                  />
                </Grid>

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.3}
                    md={1.3}
                    p={1}
                    className="blue-field-section"
                  >
                    <label
                      htmlFor="subscription"
                      style={{
                        width: "129px",
                        lineBreak: "anywhere",
                        marginTop: "-26px",
                      }}
                    >
                      Distributor Brand Name
                    </label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        formik.values?.egSubscriptions[index]?.distBrandName
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.4}
                    md={1.4}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="subscription">Type</label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        ContentTypeEnum[
                          formik.values?.egSubscriptions[index]?.documentType
                        ]
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="frequencyType">Frequency</label>
                    <input
                      id="subscription"
                      data-testid={"subscription-dropdown"}
                      name="subscription"
                      value={
                        AllFrequencyType[
                          formik.values?.egSubscriptions[index]?.frequencyType
                        ]
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleChangeValue(e, "subscriptionId");
                      }}
                      className="form-control form-select"
                      disabled
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.5}
                    md={1.5}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="associationId">Auto Renewal</label>
                    <FormControlLabel
                      className={
                        !isEditMode
                          ? "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm border-cus-cus-disabled"
                          : "content-checkbox-field form-label border-cus-cus form-control input-border  cuss-cus-fm"
                      }
                      label={<Typography>Auto Renewal</Typography>}
                      checked={
                        formik.values.egSubscriptions[index]?.isRecurring
                      }
                      name="Available Public"
                      data-testid="preview-available-checkbox"
                      control={<Checkbox disabled />}
                    />
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.6}
                    md={1.6}
                    p={1}
                    className="blue-field-section"
                  >
                    <label htmlFor="associationId">Subscription Amount</label>
                    <CurrencyTextbox
                      id="associationId"
                      name="associationId"
                      value={formik.values?.egSubscriptions[
                        index
                      ]?.subscriptionFees.toFixed(2)}
                      disabled
                      type="number"
                    />
                  </Grid>
                )}

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section date-picker-matui desktop-date-picker"
                  >
                    <label htmlFor="startDate">Start Date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control date-field"
                        inputFormat="MM/DD/YYYY"
                        value={
                          formik.values?.egSubscriptions[index]?.startDate ||
                          null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          handleChangeValueegSubscription(
                            e,
                            "startDate",
                            index
                          );
                        }}
                        renderInput={(params) => (
                          <TextField id="startDate" {...params} />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                    {formik.errors?.egSubscriptions?.length > 0 &&
                      index < formik.errors?.egSubscriptions?.length && (
                        <InlineMessage
                          error={
                            formik.errors?.egSubscriptions[index]?.startDate
                          }
                        />
                      )}
                  </Grid>
                )}

                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.2}
                    md={1.2}
                    p={1}
                    className="blue-field-section date-picker-matui desktop-date-picker"
                  >
                    <label htmlFor="endDate"> End Date</label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        disablePast
                        className="form-label form-control date-field"
                        inputFormat="MM/DD/YYYY"
                        value={
                          formik.values?.egSubscriptions[index]?.endDate || null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          handleChangeValueegSubscription(e, "endDate", index);
                        }}
                        renderInput={(params) => (
                          <TextField id="endDate" {...params} />
                        )}
                        disabled
                      />
                    </LocalizationProvider>
                    {formik.errors?.egSubscriptions?.length > 0 &&
                      index < formik.errors?.egSubscriptions?.length && (
                        <InlineMessage
                          error={formik.errors?.egSubscriptions[index]?.endDate}
                        />
                      )}
                  </Grid>
                )}
                {props?.routeData?.userTypeId === 7 && (
                  <Grid
                    item
                    xs={1.3}
                    md={1.3}
                    p={1}
                    className=" blue-field-section"
                  >
                    <Button
                      style={{ marginTop: "20px" }}
                      onClick={() => {
                        renewalhistory(formik.values?.egSubscriptions[index]);
                      }}
                    >
                      Renewal History
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const renewalhistory = (item) => {
    setAssociationPopupConfirmation(true);
    getRenewalHistory(item);
  };

  const renderMultiSelectRolesDropdown = () => {
    return (
      <div sx={{ width: "100%" }}>
        <label htmlFor="rolesDropdown">Roles</label>
        <MandatoryField />
        <Select
          displayEmpty
          disabled={
            formik.values.userId ||
            !isEditMode ||
            props?.routeData?.userTypeId === SystemUsersEnum.SUBSCRIBER
          }
          data-testid="rolesDropdown"
          labelId="mutiple-select-label"
          className={
            formik.values?.systemGeneratedId
              ? "form-select form-control select-user roles-dropdown-disabled"
              : "form-select form-control select-user roles-dropdown"
          }
          title={selected}
          multiple
          value={selected}
          onChange={handleChangeDropDown}
          renderValue={(roles) => {
            if (selected.length === 0) {
              return "Select Roles";
            }
            return roles.join(", ");
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        <InlineMessage error={formik.errors.roles} />
      </div>
    );
  };

  const addNewSubscriptionFrequency = (index) => {
    let currentData = formik.values.distributorSubscriptionDomains;
    if (currentData && currentData.length > 0 && index < currentData.length) {
      const frequencyPlan = {
        baseValue: "",
        distributorSubscriptionPlanId: 0,
        distributorSubscriptionId: 0,
        uniqueId: Math.floor(Math.random() * 1000),
        frequency: 0,
        effectiveFromDate: null,
        effectiveToDate: null,
        savedEffectiveToDate: null,
        isAssociated: false,
      };
      currentData[index].subscriptionPlan = [
        frequencyPlan,
        ...currentData[index].subscriptionPlan,
      ];
    }
    formik.setFieldValue("distributorSubscriptionDomains", currentData);
  };
  const addNewSubscription = () => {
    const addSubscriptionInitialValues = {
      distributorSubscriptionId: 0,
      uid: Math.floor(Math.random() * 1000),
      name: "",
      status: 0,
      subscriptionPlan: [
        {
          baseValue: "",
          distributorSubscriptionPlanId: 0,
          distributorSubscriptionId: 0,
          uniqueId: Math.floor(Math.random() * 1000),
          frequency: 0,
          effectiveFromDate: null,
          effectiveToDate: null,
          savedEffectiveToDate: null,
          isAssociated: false,
        },
      ],
    };
    let newData = [
      addSubscriptionInitialValues,
      ...formik.values.distributorSubscriptionDomains,
    ];
    formik.setFieldValue("distributorSubscriptionDomains", newData);
  };

  const handleSubscriptionChanges = (e, index, field, subIndex = undefined) => {
    if (subIndex === undefined) {
      formik.setFieldValue(
        `distributorSubscriptionDomains[${index}].${field}`,
        e.target.value
      );
    } else {
      if (["effectiveFromDate", "effectiveToDate"].includes(field)) {
        try {
          let d = e && e._d ? e._d : "";
          formik.setFieldValue(
            `distributorSubscriptionDomains[${index}].subscriptionPlan[${subIndex}].${field}`,
            d ? moment(d).format("YYYY-MM-DD") : null
          );
        } catch (error) {
          console.log("date error", error);
        }
      } else {
        forNormalSubPlanFields(field, formik, index, subIndex, e);
      }
    }
    // }
  };

  const deleteSubscription = () => {
    let newList = formik.values.distributorSubscriptionDomains;
    const newSubPlanList = newList
      .find((item) => item.uid === selectedItem)
      .subscriptionPlan.filter((item) => item.uniqueId !== selectedSubItem);
    if (!newSubPlanList || newSubPlanList.length < 1) {
      newList = newList.filter((item) => item.uid !== selectedItem);
    } else {
      newList.map((el) => {
        if (el.uid === selectedItem) {
          el.subscriptionPlan = newSubPlanList;
        }
        return el;
      });
    }
    formik.setFieldValue("distributorSubscriptionDomains", newList);
    formik.setValues({
      ...formik.values,
      distributorSubscriptionDomains: newList,
    });
  };

  const getDateAlreadyExistsValidation = () => {
    let response = false;
    formik.values.distributorSubscriptionDomains?.every((item) => {
      item.subscriptionPlan?.every((subItem) => {
        let { minFromDate } = getMinFromAndToDate(item, subItem);
        let effFromDateRangeExists =
          !subItem.distributorSubscriptionPlanId > 0 &&
          subItem.effectiveFromDate &&
          new Date(
            subItem.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")
          ) <
            new Date(
              new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
            );
        let effToDateRangeExists =
          !subItem.distributorSubscriptionPlanId > 0 &&
          subItem.effectiveToDate &&
          new Date(
            subItem.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")
          ) <
            new Date(
              new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
            );
        if (effFromDateRangeExists || effToDateRangeExists) {
          response = true;
          return false;
        }
        return true;
      });
      if (response) {
        return false;
      }
      return true;
    });
    return response;
  };
  const renderDistSubscriptionItem = (item, index) => {
    let subPlans = applyFilterOnPlan(item, filterQuery);
    return hasSubPlans(subPlans) ? (
      <div
        style={{ paddingBottom: "5px" }}
        key={item.uid}
        className={`subscription${index}`}
      >
        <Grid container className="blue-field-section">
          <Grid item xs={3} p={1} className="inner-container">
            <label htmlFor="subscriptionName">Subscription Name</label>
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
            <input
              id="subscriptionName"
              name="subscriptionName"
              data-testid="subscriptionName"
              onChange={(e) => {
                formik.setFieldValue("dirty", true);
                handleSubscriptionChanges(e, index, "name");
              }}
              className="form-label form-control"
              type="text"
              value={formik.values.distributorSubscriptionDomains[index].name}
              placeholder="Enter Subscription Name"
              disabled={
                !isEditMode ||
                formik.values.distributorSubscriptionDomains[index]
                  .distributorSubscriptionId > 0
              }
              maxLength={50}
            />
            <InlineMessage error={NameValidationMessage(formik, index)} />
          </Grid>
        </Grid>
        <Grid container className="blue-field-section">
          <Grid
            item
            xs={2.75}
            pl={"10px"}
            className="base-value  currency-text"
          >
            <label htmlFor="baseValue">Base Value</label>
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </Grid>
          <Grid item xs={2.75} pl={"10px"}>
            <label htmlFor="frequencyDropdown">Frequency</label>
            <MandatoryField />
          </Grid>
          <Grid
            item
            xs={2.75}
            className="blue-field-section date-picker-matui desktop-date-picker"
          >
            <label htmlFor="effectiveFromDate">Effective From Date</label>
            <MandatoryField />
          </Grid>
          <Grid
            item
            xs={2.75}
            pl={"10px"}
            className="blue-field-section date-picker-matui desktop-date-picker"
          >
            <label htmlFor="effectiveToDate">Effective To Date</label>
          </Grid>
        </Grid>
        {item.subscriptionPlan?.map((subItem, subIndex) => {
          let topIndex = item.subscriptionPlan.findIndex((x) =>
            addButtonIndex(filterQuery, x)
          );
          let { minFromDate, minToDate } = getMinFromAndToDate(item, subItem);
          let { effFromDateRangeExists, effToDateRangeExists } =
            effectiveDateRange(subItem, minFromDate);

          return subPlanValidation(filterQuery, subItem) ? (
            <div
              key={subItem.uniqueId}
              style={{ paddingBottom: "5px", paddingTop: "5px" }}
              className={activeInactiveGlobalClass(index, formik, subIndex)}
            >
              <Grid
                container
                className={activeInactiveGridClass(formik, index, subIndex)}
              >
                <Grid
                  item
                  xs={2.75}
                  pl={"10px"}
                  className="base-value currency-text"
                >
                  <CurrencyTextbox
                    id="baseValue"
                    name="baseValue"
                    value={
                      formik.values.distributorSubscriptionDomains[index]
                        ?.subscriptionPlan[subIndex]?.baseValue
                    }
                    onChange={(e) => {
                      setBaseValue(formik, index, subIndex, e, page);
                    }}
                    placeholder="Enter Base Value"
                    disabled={
                      !isEditMode ||
                      formik.values.distributorSubscriptionDomains[index]
                        .subscriptionPlan[subIndex]
                        .distributorSubscriptionPlanId > 0
                    }
                    data-testid="currency"
                    type="number"
                  />

                  <InlineMessage
                    error={baseValueValidation(formik, index, subIndex)}
                  />
                </Grid>

                <Grid item xs={2.75} pl={"10px"} pr={"10px"}>
                  <select
                    name="subscriptionFrequency"
                    id="subscriptionFrequency"
                    value={
                      formik.values.distributorSubscriptionDomains[index]
                        .subscriptionPlan[subIndex].frequency
                    }
                    onChange={(e) => {
                      formik.setFieldValue("dirty", true);
                      handleSubscriptionChanges(
                        e,
                        index,
                        "frequency",
                        subIndex
                      );
                    }}
                    className="form-control form-select"
                    disabled={
                      !isEditMode ||
                      formik.values.distributorSubscriptionDomains[index]
                        .subscriptionPlan[subIndex]
                        .distributorSubscriptionPlanId > 0
                    }
                  >
                    <option selected="true" disabled="true" value={0}>
                      Select Frequency
                    </option>
                    {getFrequencyOptions(frequencyOptions, item, subItem)}
                  </select>
                </Grid>
                <Grid
                  item
                  xs={2.75}
                  className="date-picker-matui desktop-date-picker"
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      minDate={new Date(Math.max(minFromDate, new Date()))}
                      disabled={
                        !isEditMode ||
                        formik.values.distributorSubscriptionDomains[index]
                          .subscriptionPlan[subIndex]
                          .distributorSubscriptionPlanId > 0 ||
                        !subItem.frequency > 0
                      }
                      className="form-label form-control date-field"
                      value={
                        formik.values.distributorSubscriptionDomains[index]
                          ?.subscriptionPlan[subIndex]?.effectiveFromDate ||
                        null
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleSubscriptionChanges(
                          e,
                          index,
                          "effectiveFromDate",
                          subIndex
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="effectiveFromDate"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "MM/DD/YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {
                    <InlineMessage
                      error={getEFromDateValidationMessage(
                        formik,
                        index,
                        subIndex,
                        effFromDateRangeExists
                      )}
                    />
                  }
                </Grid>
                <Grid
                  item
                  xs={2.75}
                  pl={"10px"}
                  className="date-picker-matui desktop-date-picker"
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      minDate={new Date(Math.max(minToDate, new Date()))}
                      disabled={disableEToDate(isEditMode, subItem, item)}
                      className="form-label form-control date-field"
                      value={
                        formik.values.distributorSubscriptionDomains[index]
                          ?.subscriptionPlan[subIndex]?.effectiveToDate || null
                      }
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        handleSubscriptionChanges(
                          e,
                          index,
                          "effectiveToDate",
                          subIndex
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="effectiveToDate"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "MM/DD/YYYY",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {
                    <InlineMessage
                      error={getEToDateValidationMessage(
                        formik,
                        index,
                        subIndex,
                        effToDateRangeExists
                      )}
                    />
                  }
                </Grid>

                <Grid
                  item
                  xs={1}
                  mt={-1}
                  className="add-association top-center-actions"
                >
                  {renderAddButton(
                    isEditMode,
                    subIndex,
                    topIndex,
                    addNewSubscriptionFrequency,
                    index
                  )}
                  {isEditMode && (
                    <Grid
                      style={{
                        paddingLeft: "6px",
                        paddingTop: "1px",
                        paddingRight: "20px",
                      }}
                    >
                      <IconButton
                        data-testid="btn-del"
                        disabled={subItem.isAssociated}
                        // className="delete-btn"
                        aria-label="delete"
                        color="primary"
                        onClick={() => {
                          handleEditModalCancelClick(item, subItem);
                        }}
                      >
                        {renderActiveInactiveDeleteButton(subItem)}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : null;
        })}
      </div>
    ) : null;
  };

  const onCancelHandler = () => {
    if (
      auth.getRole() === SystemUsersEnum.DISTRIBUTOR ||
      auth.getRole() === SystemUsersEnum.DISTRIBUTOR_INTERNAL
    ) {
      props.getEditData(formik.values.userId);
      setIsEditMode(false);
      handleCancelClick();
    } else if (
      props?.routeData?.userTypeId === 1 ||
      props?.routeData?.userTypeId === 2 ||
      props?.routeData?.userTypeId === 3 ||
      props?.routeData?.userTypeId === 7
    ) {
      handleCancelClick();
    } else {
      setIsEditMode(false);
    }
  };

  const onEditHandler = () => {
    setIsEditMode(true);
    setShowAnotherNumber(true);
    setShowAnotherFax(true);
    setShowAnotherMobile(true);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    let ext = getFileExtension(selectedFile.name);
    ext = `image/${ext}`;
    if (!brandLogoAcceptedFormats.includes(ext.toLowerCase())) {
      e.target.value = null;
      toast.error(`Please select a valid file`);
      setLogo("");
      return;
    }
    setLogo(e.target.files[0]);
    const formData = new FormData();
    formData.append("File", selectedFile);
    showLoader(true);
    try {
      const response = await ApiServices.httpPostFile(UPLOAD_LOGO, formData);
      showLoader(false);
      if (response.responseStatus?.isSuccess) {
        formik.setFieldValue("logoGcpPath", response.result.gcpFilePath);
        formik.setFieldValue("logoGcpFileUrl", response.result.gcpFileUrl);
      } else {
        toast.error(response.responseStatus?.error);
      }
    } catch (err) {
      showLoader(false);
    }
  };
  const onDeleteFileHandler = () => {
    formik.setFieldValue("logoGcpPath", "");
    formik.setFieldValue("logoGcpFileUrl", "");
    setLogo("");
  };

  const getValueForField = (value, type) => {
    if (value?.length && value[0].label) {
      return value;
    } else if (value?.length && !value[0].label && type === "ent") {
      return getValueForEnterpriseGroupId(value);
    } else if (value?.length && !value[0].label && type === "dist") {
      return getValueForDistributorId(value);
    } else {
      return value;
    }
  };

  return (
    <>
      <Confirmation
        open={openConfirmation}
        title={deleteSubscriptionConfirmationMessage}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onConfirm={() => {
          deleteSubscription();
          setOpenConfirmation(false);
        }}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
      />

      {/* Basic Details Screen starts for distributors/subscribers */}
      {loadBasidDetails(basicDetailPermission, props) && (
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>Basic Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <div>
              <div className="modal-body" ref={myRef}>
                <Grid container spacing={2} className="blue-field-section">
                  {
                    <>
                      <Grid item xs={4} p={2} className="blue-field-section">
                        {renderMultiSelectRolesDropdown()}
                      </Grid>
                    </>
                  }

                  {props?.routeData?.userTypeId === 3 && (
                    <Grid item xs={4} p={2} className="blue-field-section">
                      <label htmlFor="brandName">Brand Name</label>
                      <MandatoryField />
                      <input
                        id="brandName"
                        name="brandName"
                        onChange={(e) => {
                          formik.setFieldValue("dirty", true);
                          formik.handleChange(e);
                          formik.setFieldValue("businessName", e.target.value);
                        }}
                        className="form-label form-control"
                        type="text"
                        placeholder="Enter Brand Name"
                        value={formik.values.brandName}
                        disabled={!isEditMode}
                      />
                      <InlineMessage error={formik.errors.brandName} />
                    </Grid>
                  )}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="firstName">First Name</label>
                    <MandatoryField />
                    <input
                      id="firstName"
                      name="firstName"
                      onChange={(e) => handleFieldChange("firstName", e)}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter First Name"
                      value={formik.values.firstName}
                      disabled={!isEditMode}
                      maxLength={25}
                    />
                    <InlineMessage error={formik.errors.firstName} />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="lastName">Last Name</label>
                    <MandatoryField />
                    <input
                      id="lastName"
                      name="lastName"
                      onChange={(e) => handleFieldChanges("lastName", e)}
                      value={formik.values.lastName}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Last Name"
                      disabled={!isEditMode}
                      maxLength={25}
                    />
                    <InlineMessage error={formik.errors.lastName} />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="distributorType">
                      {props.routeData?.userTypeLabel}
                    </label>
                    <MandatoryField />
                    <MultiSelectDropDown
                      name="distributorType"
                      id="distributorType"
                      value={formik.values.distributorType}
                      onChange={(value) => {
                        formik.setValues({
                          ...formik.values,
                          distributorType: value,
                          dirty: true,
                        });
                      }}
                      options={distributorTypeOptions}
                      disabled={!isEditMode}
                    />
                    <InlineMessage
                      error={
                        formik.errors.distributorType &&
                        props?.routeData?.distTypeError
                      }
                    />
                  </Grid>

                  {entBrandNameSection()}
                  {distBrandNameSection()}

                  {primaryEmailAdmin(
                    auth,
                    isEditMode,
                    editMode,
                    formik,
                    handleEditClick
                  )}
                  {props?.routeData?.userTypeId === 3 && (
                    <Grid item xs={4} p={2} className="blue-field-section">
                      <label
                        htmlFor="application"
                        className="file-upload-label"
                      >
                        <span>Logo </span>
                        {getLogoText(logo, formik)}
                      </label>
                      <FileUploader
                        accept={brandLogoAcceptedFormats}
                        onSelectFile={handleFileChange}
                        onDeleteFile={onDeleteFileHandler}
                        maxSizeInBytes={maxSizeInBytes}
                        maxSizeExeedError={maxSizeExeedError}
                        disabled={!isEditMode}
                        sourceFile={logo}
                      />
                      {formik.values.logoGcpPath !== "" && (
                        <div>{formik.values.logoGcpPath}</div>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="secondaryEmail">
                      Secondary Email Address
                    </label>
                    <input
                      id="secondaryEmail"
                      name="secondaryEmail"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.secondaryEmail}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Secondary Email Address"
                      disabled={!isEditMode}
                    />
                    <InlineMessage error={formik.errors.secondaryEmail} />
                  </Grid>

                  {props?.routeData?.userTypeId === 3 && (
                    <Grid item xs={4} p={2} className="blue-field-section">
                      <label htmlFor="carriers">Carrier</label>
                      <MultiSelectDropDown
                        id="carriers"
                        name="carriers"
                        value={formik.values.carriers}
                        isClearable={() => setViewForm(!viewForm)}
                        menuShouldBlockScroll
                        isMulti
                        options={carrier}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            carriers: value,
                            dirty: true,
                          });
                        }}
                        disabled={!isEditMode}
                      />
                    </Grid>
                  )}

                  {["agent", "all"].includes(type) && (
                    <>
                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="carrierRepresents">
                          Carriers represented
                        </label>

                        <MultiSelectDropDown
                          id="carrierRepresents"
                          name="carrierRepresents"
                          value={formik.values.carrierRepresents}
                          isClearable={() => setViewForm(!viewForm)}
                          menuShouldBlockScroll
                          isMulti
                          options={career}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              carrierRepresents: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="areas">Major Areas</label>

                        <MultiSelectDropDown
                          id="areas"
                          name="areas"
                          value={formik.values.areas}
                          isMulti
                          options={areaOptions}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              areas: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="producers">Number of producers</label>
                        <input
                          id="producers"
                          name="producers"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.producers}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of producers"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.producers} />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="industryGroups">
                          Industry Groups/ Membership
                        </label>
                        <MultiSelectDropDown
                          id="industryGroups"
                          name="industryGroups"
                          value={formik.values.industryGroups}
                          isMulti
                          options={industry}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              industryGroups: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>
                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="technologies">
                          Technology being used now
                        </label>

                        <MultiSelectDropDown
                          id="technologies"
                          name="technologies"
                          value={formik.values.technologies}
                          isMulti
                          options={technology}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              technologies: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="businessYear">Years in Business</label>
                        <input
                          id="businessYear"
                          name="businessYear"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.businessYear}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Years in Business"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.businessYear} />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="websiteUrl">Website URL</label>
                        <input
                          id="websiteUrl"
                          name="websiteUrl"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.websiteUrl}
                          className="form-label form-control"
                          type="url"
                          placeholder="Enter Website URL"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.websiteUrl} />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="annualRevenue">Annual revenue</label>

                        <select
                          id="annualRevenue"
                          name="annualRevenue"
                          value={formik.values.annualRevenue}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-control form-select"
                          disabled={!isEditMode}
                        >
                          <option value="0">Select Revenue</option>
                          {annualRevenues?.map((res) => {
                            return (
                              <option
                                key={res.annualRevenueId}
                                value={res.annualRevenueId}
                              >
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="numberOfEmployees">
                          Number of employees
                        </label>
                        <input
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfEmployees}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of employees"
                          disabled={!isEditMode}
                        />
                        <InlineMessage
                          error={formik.errors.numberOfEmployees}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="numberOfOwners">
                          Number of principals / owners
                        </label>
                        <input
                          id="numberOfOwners"
                          name="numberOfOwners"
                          value={formik.values.numberOfOwners}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of principals / owners"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.numberOfOwners} />
                      </Grid>
                    </>
                  )}

                  {type === "other" && (
                    <>
                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="busineesYear">Years in Business </label>
                        <input
                          id="businessYear"
                          name="businessYear"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.businessYear}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Years in Business"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.businessYear} />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="websiteUrl">Website URL</label>
                        <input
                          id="websiteUrl"
                          value={formik.values.websiteUrl}
                          name="websiteUrl"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Website URL"
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="annualRevenue">Annual revenue</label>
                        <select
                          id="annualRevenue"
                          name="annualRevenue"
                          value={formik.values.annualRevenue}
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          className="form-control form-select"
                          disabled={!isEditMode}
                        >
                          <option value="0">Select Revenue</option>
                          {annualRevenues?.map((res) => {
                            return (
                              <option
                                key={res.annualRevenueId}
                                value={res.annualRevenueId}
                              >
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="numberOfEmployees">
                          Number of employees
                        </label>
                        <input
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfEmployees}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of employees"
                          disabled={!isEditMode}
                        />
                        <InlineMessage
                          error={formik.errors.numberOfEmployees}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="numberOfOwners">
                          Number of principals / owners
                        </label>
                        <input
                          id="numberOfOwners"
                          name="numberOfOwners"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik.values.numberOfOwners}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Number of principals / owners"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.numberOfOwners} />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="industryGroups">
                          Industry Groups/ Membership
                        </label>
                        <MultiSelectDropDown
                          id="industryGroups"
                          name="industryGroups"
                          value={formik.values.industryGroups}
                          isMulti
                          options={industry}
                          onChange={(value) => {
                            formik.setValues({
                              ...formik.values,
                              industryGroups: value,
                              dirty: true,
                            });
                          }}
                          disabled={!isEditMode}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <MandatoryField />
                    <BusinessPhoneNumberTextbox
                      id="phoneNumber"
                      className="form-label form-control"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.setFieldValue("phoneNumber", e);
                      }}
                      value={formik.values.phoneNumber}
                      disabled={!isEditMode}
                      phoneCode={getPhoneCode(
                        countryOptions,
                        formik.values.countryId
                      )}
                    />
                    <InlineMessage error={formik.errors.phoneNumber} />
                  </Grid>
                  {secNumberMissingSection()}

                  {secNumberExistSection()}

                  {props.routeData.addRoute === "/subscriber" ? null : (
                    <>
                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="businessName">Business Name</label>
                        <input
                          id="businessName"
                          name="businessName"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik?.values?.businessName}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Business Name"
                          disabled={!isEditMode}
                        />
                      </Grid>

                      <Grid item xs={4} p={2} className="blue-field-section">
                        <label htmlFor="taxID">Tax Id</label>
                        <input
                          id="taxID"
                          name="taxID"
                          onChange={(e) => {
                            formik.setFieldValue("dirty", true);
                            formik.handleChange(e);
                          }}
                          value={formik?.values?.taxID}
                          className="form-label form-control"
                          type="text"
                          placeholder="Enter Tax ID"
                          disabled={!isEditMode}
                        />
                        <InlineMessage error={formik.errors.taxID} />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="mobile">Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile"
                      className="form-label form-control"
                      name="mobile"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.mobile}
                      disabled={!isEditMode}
                      phoneCode={getPhoneCode(
                        countryOptions,
                        formik.values.countryId
                      )}
                    />
                    <InlineMessage error={formik.errors.mobile} />
                  </Grid>

                  {anotherMobileMissingsection()}

                  {anotherMobileExistSection()}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="fax">Fax</label>
                    <PhoneNumberTextbox
                      id="fax"
                      className="form-label form-control"
                      name="fax"
                      placeholder="Enter Fax"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.fax}
                      disabled={!isEditMode}
                      phoneCode={getPhoneCode(
                        countryOptions,
                        formik.values.countryId
                      )}
                    />
                    <InlineMessage error={formik.errors.fax} />
                  </Grid>

                  {altFaxMissingSection()}

                  {altFaxExistSection()}

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="billingContact">Billing First Name</label>
                    <input
                      id="billingContact"
                      name="billingContact"
                      onChange={(e) => handleFieldChange("billingContact", e)}
                      value={formik.values.billingContact}
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing First Name"
                      disabled={!isEditMode}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="billingContactLastName">
                      Billing Last Name{" "}
                    </label>
                    <input
                      id="billingContactLastName"
                      name="billingContactLastName"
                      onChange={(e) =>
                        handleFieldChange("billingContactLastName", e)
                      }
                      value={formik.values.billingContactLastName}
                      className="form-label form-control"
                      type="text"
                      placeholder="Billing Last Name"
                      disabled={!isEditMode}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="billingPhoneNumber">
                      Billing Phone Number
                    </label>
                    <PhoneNumberTextbox
                      id="billingPhoneNumber"
                      className="form-label form-control"
                      name="billingPhoneNumber"
                      placeholder="Enter Billing Phone Number"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      value={formik.values.billingPhoneNumber}
                      disabled={!isEditMode}
                      phoneCode={getPhoneCode(
                        countryOptions,
                        formik.values.countryId
                      )}
                    />
                    <InlineMessage error={formik.errors.billingPhoneNumber} />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="billingAddressLine1">
                      Billing Address Line 1
                    </label>
                    <input
                      id="billingAddressLine1"
                      name="billingAddressLine1"
                      value={formik.values.billingAddressLine1}
                      className="form-label form-control"
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      type="text"
                      placeholder="Enter Billing Address Line 1 "
                      disabled={!isEditMode}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="billingAddressLine2">
                      Billing Address Line 2
                    </label>
                    <input
                      id="billingAddressLine2"
                      name="billingAddressLine2"
                      value={formik.values.billingAddressLine2}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Billing Address Line 2"
                      disabled={!isEditMode}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="zip">Zip Code</label>
                    <input
                      id="zip"
                      name="zip"
                      className="form-label form-control"
                      type="text"
                      maxLength={5}
                      placeholder="Enter Zip Code"
                      value={formik.values.zip}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      disabled={!isEditMode}
                    />

                    <InlineMessage error={formik.errors.zip} />
                  </Grid>
                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="city">City</label>
                    <input
                      name="city"
                      id="city"
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter City"
                      disabled={!isEditMode}
                    />
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="countryId">Country</label>
                    <select
                      id="countryId"
                      name="countryId"
                      value={formik.values?.countryId}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-control form-select"
                      disabled={!isEditMode}
                    >
                      {countryOptions?.map((res) => {
                        return (
                          <option key={res.countryId} value={res.countryId}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>

                  <Grid item xs={4} p={2} className="blue-field-section">
                    <label htmlFor="stateId">State</label>
                    <select
                      id="stateId"
                      name="stateId"
                      value={formik.values.stateId}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter State"
                      disabled={!isEditMode}
                    >
                      <option>Select State</option>
                      {stateOptions?.map((res) => {
                        return (
                          <option key={res.stateId} value={res.stateId}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                </Grid>
              </div>
            </div>
            <FooterButtons
              isEditMode={isEditMode}
              permissions={permissions}
              permissionId={permissionId}
              editPermission={
                (props.formData.status > 0 ||
                  props.routeData?.userTypeId !==
                    SystemUsersEnum.DISTRIBUTOR) &&
                basicDetailPermission.includes(PermissionEnum.UPDATE)
              }
              showUpdateNext={
                formik.values.userId > 0 && props?.routeData?.userTypeId !== 7
              }
              showSaveNext={
                !formik.values.userId && props?.routeData?.userTypeId !== 7
              }
              disableUpdateNext={
                !isEditMode ||
                !formik.isValid ||
                getDateAlreadyExistsValidation()
              }
              disableSaveNext={
                !isEditMode ||
                !formik.isValid ||
                getDateAlreadyExistsValidation()
              }
              showUpdateDetail={formik.values.userId > 0}
              disableUpdateDetail={
                !isEditMode ||
                Object.keys(formik.errors).length > 0 ||
                getDateAlreadyExistsValidation()
              }
              disableSaveDetail={
                !isEditMode ||
                !formik.isValid ||
                getDateAlreadyExistsValidation()
              }
              onCancel={onCancelHandler}
              onEdit={onEditHandler}
              onUpdateNext={() => updateDistributor(true)}
              onSaveNext={() => {
                createDistributor(true);
              }}
              onUpdate={() => updateDistributor(false)}
              onSave={() => {
                createDistributor(false);
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {/* Basic Details Screen ends*/}

      {/* association Details Screen starts for distributors */}
      {associationDetailsPermission.includes(PermissionEnum.LIST) &&
        props.routeData.userTypeId === 3 &&
        !hidden && (
          <Accordion
            className="accordion-ent"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ fontWeight: "700" }}>
                Association Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} className="blue-field-section">
                <Grid item sx={4} p={2} className="serachable-dropdown">
                  <label htmlFor="enterpriseGroupIdDropdown">
                    Parent Distributor (if any)
                  </label>
                  <SearchableDropDown
                    isEditMode={isEditMode}
                    options={parentDropdown.map((res) => {
                      return {
                        label: res.name,
                        id: res.userId,
                      };
                    })}
                    name="enterpriseGroupIdDropdown"
                    id="enterpriseGroupIdDropdown"
                    setData={(value) => {
                      setMembership(value.id ? `INCHANTED_${value.id}` : "");
                      setSelectedValue([value]);
                      formik.setValues({
                        ...formik.values,
                        parentUser: {
                          ...formik.values.parentUser,
                          parentUserId: value.id,
                          dirty: true,
                        },
                      });
                    }}
                    value={selectedValue}
                    placeholder="Select Parent Distributor"
                    disabled={!isEditMode}
                  />
                </Grid>
                {selectedValue !== null && (
                  <Grid item sx={4} p={2}>
                    <label htmlFor="enterpriseGroupIdDropdown">
                      Membership ID
                    </label>
                    <input
                      disabled
                      id="membershipId"
                      name="membershipId"
                      className="form-label form-control"
                      type="text"
                      placeholder="Enter Membership ID"
                      value={memberShip}
                    />
                  </Grid>
                )}
              </Grid>
              {selectedValue !== null && (
                <div className="blue-field-section">
                  <div className="add-association blue-field-section">
                    Section Access to Parent Distributor
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="permission-list blue-field-section"
                  >
                    {permissionList.map((res, index) => {
                      return (
                        <Grid
                          item
                          md={12}
                          p={2}
                          key={res.parentPermissionMasterId}
                        >
                          <Typography className="basic-information">
                            {res.name}
                          </Typography>
                          <div>
                            <Checkbox
                              checked={
                                formik.values.parentUser?.permissions[index]
                                  ?.isRead
                              }
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                permissionChange(
                                  index,
                                  res.parentPermissionMasterId,
                                  "isRead"
                                );
                              }}
                              disabled={!isEditMode}
                            />
                            Read
                            <Checkbox
                              checked={
                                formik.values.parentUser?.permissions[index]
                                  ?.isWrite
                              }
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                permissionChange(
                                  index,
                                  res.parentPermissionMasterId,
                                  "isWrite"
                                );
                              }}
                              disabled={!isEditMode}
                            />
                            Write
                            <Checkbox
                              checked={
                                formik.values.parentUser?.permissions[index]
                                  ?.isNoAccess
                              }
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                permissionChange(
                                  index,
                                  res.parentPermissionMasterId,
                                  "isNoAccess"
                                );
                              }}
                              disabled={!isEditMode}
                            />
                            No Access <br></br>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
              <FooterButtons
                isEditMode={isEditMode}
                permissions={permissions}
                permissionId={permissionId}
                editPermission={
                  (props.formData.status > 0 ||
                    props.routeData?.userTypeId !==
                      SystemUsersEnum.DISTRIBUTOR) &&
                  associationDetailsPermission.includes(PermissionEnum.UPDATE)
                }
                showUpdateNext={
                  formik.values.userId > 0 && props?.routeData?.userTypeId !== 7
                }
                showSaveNext={
                  !formik.values.userId && props?.routeData?.userTypeId !== 7
                }
                disableUpdateNext={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                disableSaveNext={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                showUpdateDetail={formik.values.userId > 0}
                disableUpdateDetail={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                disableSaveDetail={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                onCancel={onCancelHandler}
                onEdit={onEditHandler}
                onUpdateNext={() => updateDistributor(true)}
                onSaveNext={() => {
                  createDistributor(true);
                }}
                onUpdate={() => {
                  updateDistributor(false);
                }}
                onSave={() => {
                  createDistributor(false);
                }}
              />
            </AccordionDetails>
          </Accordion>
        )}
      {/* Association Details Screen ends */}

      {/*  Subscription Details Screen starts for distributors */}
      {subscriptionDetailsPermission?.includes(PermissionEnum.LIST) &&
        props.routeData.userTypeId === 3 && (
          <Accordion
            className="accordion-ent"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography sx={{ fontWeight: "700" }}>
                Subscription Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                <Grid container spacing={2} className="blue-field-section">
                  {isEditMode && (
                    <Grid item sx={12} p={2}>
                      <Typography className="add-association">
                        Add the subscriptions you offer.
                        <Tooltip placement="bottom" title="Add">
                          <AddOutlinedIcon
                            name="add-icon"
                            title="add-icon"
                            onClick={() => {
                              addNewSubscription();
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    </Grid>
                  )}
                  {formik.values.distributorSubscriptionDomains &&
                    formik.values.distributorSubscriptionDomains.length > 0 && (
                      <Grid item className="top-right-actions" xs={12}>
                        <div
                          style={{ paddingTop: "10px" }}
                          className="add-welcome-widgets-content"
                        >
                          <Popover
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            id={"simple-popover"}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => {
                              handleClose();
                            }}
                          >
                            <div className="expanded-filter">
                              <div className="filter-options">
                                <List
                                  sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                  }}
                                  component="nav"
                                  aria-labelledby="nested-list-subheader"
                                  subheader={
                                    <ListSubheader
                                      component="div"
                                      id="nested-list-subheader"
                                    >
                                      Filters will be applied to your grid
                                    </ListSubheader>
                                  }
                                >
                                  <Accordion
                                    expanded={expandedFilter === "status"}
                                    onChange={filterAccordianHandleChange(
                                      "status"
                                    )}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <Typography sx={{ flexShrink: 0 }}>
                                        Status
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <CheckboxList
                                        id="status"
                                        options={subscriptionStatusFilters}
                                        onChange={onFilterStatusChangeHandler}
                                        selectedValues={selectedStatus}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion
                                    expanded={expandedFilter === "frequency"}
                                    onChange={filterAccordianHandleChange(
                                      "frequency"
                                    )}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <Typography sx={{ flexShrink: 0 }}>
                                        Frequency
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <CheckboxList
                                        id="frequency"
                                        options={frequencyOptions}
                                        onChange={onFilterFreqChangeHandler}
                                        selectedValues={selectedFreq}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </List>
                              </div>
                              <div className="modal-footer">
                                <Button
                                  variant="outlined"
                                  className="action-button mr-4"
                                  onClick={clearFilterHandler}
                                >
                                  Clear
                                </Button>
                                <Button
                                  variant="contained"
                                  className="action-button"
                                  onClick={() => {
                                    applyFilterHandler();
                                    handleClose();
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </Popover>
                          <Tooltip placement="bottom" title="Filter">
                            <FilterAltOutlinedIcon
                              onClick={handleClick}
                              title="filter-icon"
                            />
                          </Tooltip>
                        </div>
                      </Grid>
                    )}
                </Grid>
              }

              {formik.values.distributorSubscriptionDomains &&
                formik.values.distributorSubscriptionDomains?.map(
                  (item, index) => {
                    return renderDistSubscriptionItem(item, index);
                  }
                )}
              <FooterButtons
                isEditMode={isEditMode}
                permissions={permissions}
                permissionId={permissionId}
                editPermission={
                  (props.formData.status > 0 ||
                    props.routeData?.userTypeId !==
                      SystemUsersEnum.DISTRIBUTOR) &&
                  subscriptionDetailsPermission.includes(PermissionEnum.UPDATE)
                }
                showUpdateNext={
                  formik.values.userId > 0 && props?.routeData?.userTypeId !== 7
                }
                showSaveNext={
                  !formik.values.userId && props?.routeData?.userTypeId !== 7
                }
                disableUpdateNext={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                disableSaveNext={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                showUpdateDetail={formik.values.userId > 0}
                disableUpdateDetail={
                  !isEditMode ||
                  Object.keys(formik.errors).length > 0 ||
                  getDateAlreadyExistsValidation()
                }
                disableSaveDetail={
                  !isEditMode ||
                  !formik.isValid ||
                  getDateAlreadyExistsValidation()
                }
                onCancel={onCancelHandler}
                onEdit={onEditHandler}
                onUpdateNext={() => updateDistributor(true)}
                onSaveNext={() => {
                  createDistributor(true);
                }}
                onUpdate={() => updateDistributor(false)}
                onSave={() => {
                  createDistributor(false);
                }}
              />
            </AccordionDetails>
          </Accordion>
        )}
      {/*  Subscription Details Screen ends */}

      {/* Enterprise Subscription Details Screen starts for subscribers */}
      {props.routeData.userTypeId === 7 && (
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>
              Subscription Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {formik.values?.egSubscriptions?.map((item, index) => {
              if (
                auth.getRole() === SystemUsersEnum.SUPERADMIN ||
                auth.getRole() === SystemUsersEnum.ADMIN
              ) {
                return renderSubscriptionDetails(item, index);
              } else {
                return item.distributorUserId ===
                  auth.getCurrentUser()?.userId ||
                  item.enterpriseUserId === auth.getCurrentUser()?.userId
                  ? renderSubscriptionDetails(item, index)
                  : null;
              }
            })}
            {associationPopupConfirmation && renderConfirmationPopup()}
          </AccordionDetails>
        </Accordion>
      )}
      {/* Enterprise Subscription Details Screen ends */}

      {/* Manage Addins Screen starts for subscribers */}
      {props.routeData.userTypeId === 7 && (
        <Accordion
          className="accordion-ent"
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography sx={{ fontWeight: "700" }}>Manage Add-Ins</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              <Grid container spacing={2} className="blue-field-section">
                <Grid item sx={12} p={2}>
                  <span>
                    <Typography>
                      <Checkbox
                        checked={
                          formik.values.userAddOns?.find(
                            (x) =>
                              x.addOnType ===
                              UserAddOnType.HIGHLIGHTING_DURING_SHARE
                          )?.isActive
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleAddOnChange(
                            formik,
                            e,
                            page,
                            UserAddOnType.HIGHLIGHTING_DURING_SHARE
                          );
                        }}
                        control={<Checkbox disabled={!isEditMode} />}
                        disableRipple
                        disabled={!isEditMode}
                        className="add-in-checkbox"
                      />
                      Share using Inchanted
                    </Typography>
                  </span>
                  <span>
                    <Typography>
                      <Checkbox
                        checked={
                          formik.values.userAddOns?.find(
                            (x) =>
                              x.addOnType === UserAddOnType.GLOSSARY_OF_TERMS
                          )?.isActive
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleAddOnChange(
                            formik,
                            e,
                            page,
                            UserAddOnType.GLOSSARY_OF_TERMS
                          );
                        }}
                        control={<Checkbox disabled={!isEditMode} />}
                        disableRipple
                        disabled={!isEditMode}
                        className="add-in-checkbox"
                      />
                      Glossary of Terms
                    </Typography>
                  </span>
                </Grid>
              </Grid>
            }
            <FooterButtons
              isEditMode={isEditMode}
              permissions={permissions}
              permissionId={permissionId}
              editPermission={basicDetailPermission.includes(
                PermissionEnum.UPDATE
              )}
              showUpdateDetail={formik.values.userId > 0}
              disableUpdateDetail={
                !isEditMode ||
                Object.keys(formik.errors).length > 0 ||
                getDateAlreadyExistsValidation()
              }
              onCancel={onCancelHandler}
              onEdit={onEditHandler}
              onUpdate={() => updateSubscriberAddOn()}
              disableSaveDetail={
                !isEditMode ||
                !formik.isValid ||
                getDateAlreadyExistsValidation()
              }
              onSave={() => {
                updateSubscriberAddOn();
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {/* Manage Addins Screen ends */}
    </>
  );

  function entBrandNameSection() {
    return (
      props?.routeData?.userTypeId === 7 &&
      (auth.getRole() === SystemUsersEnum.ADMIN ||
      auth.getRole() === SystemUsersEnum.SUPERADMIN ? (
        <Grid
          item
          sx={4}
          p={2}
          className="blue-field-section serachable-dropdown search-dropdown css-cus-css"
        >
          <label htmlFor="DistributorIDEnterpriseID">
            Enterprise Brand Name
          </label>
          <MultiSelectCheckboxSearchableDropdown
            from="subscriber"
            width="100%"
            multiple={true}
            setData={onChangeEnterpriseGroupId}
            name="searchSubscriber"
            value={getValueForField(formik.values.enterpriseIds, "ent")}
            id="searchSubscriber"
            className="multiple-associate-subscriber-dropdown"
            options={
              enterpriseTypes?.allEnterpriseUsers
                ? enterpriseTypes.allEnterpriseUsers
                : []
            }
            placeholder={!isEditMode ? "" : "Search and Select"}
            disabled={!isEditMode}
          />
        </Grid>
      ) : (
        <Grid item xs={4} p={2} className="blue-field-section">
          <label htmlFor="EnterpriseBrandName">Enterprise Brand Name</label>
          <MandatoryField />
          <input
            id="EnterpriseBrandName"
            name="EnterpriseBrandName"
            value={
              auth.getRole() === SystemUsersEnum.ENTERPRISE
                ? currentUserBrandName
                : ""
            }
            className="form-label form-control"
            type="text"
            placeholder="Enterprise Brand Name"
            disabled={true}
            maxLength={25}
          />
        </Grid>
      ))
    );
  }

  function distBrandNameSection() {
    const DistBrandNameWhenNotAdmin =
      auth.getRole() === SystemUsersEnum.ENTERPRISE ? (
        <Grid
          item
          sx={4}
          p={2}
          className="blue-field-section serachable-dropdown search-dropdown css-cus-css"
        >
          <label htmlFor="EnterpriseID">
            Associated Distributor Brand Names
          </label>
          <MultiSelectCheckboxSearchableDropdown
            from="subscriber"
            width="100%"
            multiple={true}
            name="searchSubscriber"
            value={entUserDists?.map((res) => {
              return {
                value: res.distributorUserId,
                label: res.brandName || "NA",
              };
            })}
            id="searchSubscriber"
            className="multiple-associate-subscriber-dropdown"
            options={entUserDists?.map((res) => {
              return {
                value: res.distributorUserId,
                label: res.brandName || "NA",
              };
            })}
            disabled={true}
          />
        </Grid>
      ) : (
        <Grid item xs={4} p={2} className="blue-field-section">
          <label htmlFor="DistributorBrandName">Distributor Brand Name</label>
          <MandatoryField />
          <input
            id="DistributorBrandName"
            name="DistributorBrandName"
            value={currentUserBrandName}
            className="form-label form-control"
            type="text"
            placeholder="Distributor Brand Name"
            disabled={true}
            maxLength={25}
          />
        </Grid>
      );
    return (
      props?.routeData?.userTypeId === 7 &&
      (auth.getRole() === SystemUsersEnum.ADMIN ||
      auth.getRole() === SystemUsersEnum.SUPERADMIN ? (
        <Grid
          item
          sx={4}
          p={2}
          className="blue-field-section serachable-dropdown search-dropdown css-cus-css"
        >
          <label htmlFor="EnterpriseID">Distributor Brand Name</label>
          <MultiSelectCheckboxSearchableDropdown
            from="subscriber"
            width="100%"
            multiple={true}
            setData={onChangeDistributorUserId}
            name="searchSubscriber"
            value={getValueForField(formik.values.distributorIds, "dist")}
            id="searchSubscriber"
            className="multiple-associate-subscriber-dropdown"
            options={
              distributorTypes?.allDistributorUsers
                ? distributorTypes.allDistributorUsers
                : []
            }
            placeholder={!isEditMode ? "" : "Search and Select"}
            disabled={!isEditMode}
          />
        </Grid>
      ) : (
        DistBrandNameWhenNotAdmin
      ))
    );
  }

  function altFaxExistSection() {
    return showAnotherFax || props?.formData?.fax1 ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <label htmlFor="mobile1">Alternate Fax</label>
        <PhoneNumberTextbox
          id="fax1"
          className="form-label form-control"
          name="fax1"
          placeholder="Enter Alternate Fax"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.fax1}
          disabled={!isEditMode}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
        <InlineMessage error={formik.errors.fax1} />
      </Grid>
    ) : (
      <></>
    );
  }

  function altFaxMissingSection() {
    return (!props?.formData?.userId && !props?.formData?.fax1) ||
      (isEditMode && !props?.formData?.fax1) ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <div
          role="button"
          name="AddAnotherFax"
          className="add-item"
          data-testid="AddAnotherFax"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                fax1: "",
              });
            }
            setShowAnotherFax((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherFax ? "- Remove Another Fax" : "+ Add Another Fax"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function anotherMobileExistSection() {
    return showAnotherMobile || props?.formData?.mobile1 ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <label htmlFor="mobile1">Alternate Mobile</label>
        <PhoneNumberTextbox
          id="mobile1"
          className="form-label form-control"
          name="mobile1"
          placeholder="Enter Alternate Mobile Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.handleChange(e);
          }}
          value={formik.values.mobile1}
          disabled={!isEditMode}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
        <InlineMessage error={formik.errors.mobile1} />
      </Grid>
    ) : (
      <></>
    );
  }

  function anotherMobileMissingsection() {
    return (!props?.formData?.userId && !props?.formData?.mobile1) ||
      (isEditMode && !props?.formData?.mobile1) ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <div
          role="button"
          name="AddAnotherMobile"
          className="add-item"
          data-testid="AddAnotherMobile"
          onClick={() => {
            if (showAnotherMobile) {
              formik.setValues({
                ...formik.values,
                mobile1: "",
              });
            }
            setShowAnotherMobile((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherMobile
                ? "- Remove Another Mobile"
                : "+ Add Another Mobile"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }

  function secNumberExistSection() {
    return showAnotherNumber || props?.formData?.secondaryPhoneNumber ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <label htmlFor="secondaryPhoneNumber">Secondary Phone Number</label>
        <BusinessPhoneNumberTextbox
          id="secondaryPhoneNumber"
          className="form-label form-control"
          name="secondaryPhoneNumber"
          placeholder="Enter Secondary Phone Number"
          onChange={(e) => {
            formik.setFieldValue("dirty", true);
            formik.setFieldValue("secondaryPhoneNumber", e);
          }}
          value={formik.values.secondaryPhoneNumber}
          disabled={!isEditMode}
          phoneCode={getPhoneCode(countryOptions, formik.values.countryId)}
        />
        <InlineMessage error={formik.errors.secondaryPhoneNumber} />
      </Grid>
    ) : (
      <></>
    );
  }

  function secNumberMissingSection() {
    return (!props?.formData?.userId &&
      !props?.formData?.secondaryPhoneNumber) ||
      (isEditMode && !props?.formData?.secondaryPhoneNumber) ? (
      <Grid item xs={4} p={2} className="blue-field-section">
        <div
          role="button"
          name="AddAnotherNumber"
          className="add-item"
          data-testid="AddAnotherNumber"
          onClick={() => {
            if (showAnotherNumber) {
              formik.setValues({
                ...formik.values,
                secondaryPhoneNumber: "",
              });
            }
            setShowAnotherNumber((prevValue) => !prevValue);
          }}
        >
          <h5>
            <span>
              {showAnotherNumber
                ? "- Remove Another Number"
                : "+ Add Another Number"}
            </span>
          </h5>
        </div>
      </Grid>
    ) : null;
  }
};

export default CreateDistributorModal;

function handleAddOnChange(formik, e, page, addOnTypeValue) {
  let currentAddOns = [...formik.values.userAddOns];
  if (currentAddOns?.some((x) => x.addOnType === addOnTypeValue)) {
    currentAddOns = currentAddOns.map((item) => {
      if (item.addOnType === addOnTypeValue) {
        item.isActive = e.target.checked;
      }
      return item;
    });
  } else {
    currentAddOns = [
      ...currentAddOns,
      {
        userId: formik.values.userId || 0,
        addOnType: addOnTypeValue,
        isActive: e.target.checked,
      },
    ];
  }
  formik.setValues({
    ...formik.values,
    userAddOns: currentAddOns,
  });
  page.setDirty(true);
}

/*istanbul ignore next */
function renderAddButton(
  isEditMode,
  subIndex,
  topIndex,
  addNewSubscriptionFrequency,
  index
) {
  return showAddButton(isEditMode, subIndex, topIndex) ? (
    <Grid
      style={{
        paddingRight: "5px",
      }}
    >
      <Tooltip placement="bottom" title="Add">
        <AddOutlinedIcon
          name="add-icon"
          title="add-icon"
          style={{ marginLeft: "40px" }}
          onClick={() => {
            addNewSubscriptionFrequency(index);
          }}
        />
      </Tooltip>
    </Grid>
  ) : (
    <Grid
      style={{
        paddingRight: "82px",
      }}
    ></Grid>
  );
}

/*istanbul ignore next */
function renderActiveInactiveDeleteButton(subItem) {
  return subItem.isAssociated ? (
    <img src={Group2252519} alt="Disabled Delete" height={35} />
  ) : (
    <img src={Group2252459} alt="Delete" height={35} />
  );
}

/*istanbul ignore next */
function activeInactiveGridClass(formik, index, subIndex) {
  return `blue-field-section${
    formik.values.distributorSubscriptionDomains[index]?.subscriptionPlan[
      subIndex
    ]?.status === 1
      ? "active-record"
      : ""
  }`;
}

/*istanbul ignore next */
function activeInactiveGlobalClass(index, formik, subIndex) {
  return `subscription${index} ${
    formik.values.distributorSubscriptionDomains[index]?.subscriptionPlan[
      subIndex
    ]?.status === 1
      ? "active-record"
      : ""
  }`;
}

/*istanbul ignore next */
function hasSubPlans(subPlans) {
  return subPlans && subPlans.length > 0;
}

/*istanbul ignore next */
function createUserSuccess(
  page,
  response,
  setIsEditMode,
  props,
  executeScroll,
  isNext,
  history
) {
  page.setDirty(false);
  toast.success(response.responseStatus.description);
  page.setEditMode(false);
  setIsEditMode(
    (props.formData.status > 0 ||
      props.routeData?.userTypeId !== SystemUsersEnum.DISTRIBUTOR) &&
      page.isEditMode
  );
  setTimeout(() => {
    executeScroll();
  });
  setTimeout(() => {
    if (isNext) {
      props.onNext(isNext, response.result);
    } else {
      if (props.routeData?.userTypeId === 7) {
        history.push(
          `${props.routeData?.editRoute.replace(":id", response.result.userId)}`
        );
      } else {
        history.push(
          `${props.routeData?.editRoute.replace(
            ":id",
            response.result.userId
          )}/${TABS.INTERNAL_USERS}`
        );
      }
    }
  }, 3000);
}
/*istanbul ignore next */
function setRolesMethod(allRoles, selected, payload) {
  let roles =
    allRoles?.filter((element) => selected.includes(element.name)) || [];
  payload.roles = roles;
}
/*istanbul ignore next */
function showAddButton(isEditMode, subIndex, topIndex) {
  return isEditMode && subIndex === topIndex;
}
/*istanbul ignore next */
function getEToDateValidationMessage(
  formik,
  index,
  subIndex,
  effToDateRangeExists
) {
  return (
    (formik.errors?.distributorSubscriptionDomains?.length > 0 &&
      index < formik.errors.distributorSubscriptionDomains?.length &&
      formik.errors.distributorSubscriptionDomains[index]?.subscriptionPlan
        ?.length > 0 &&
      subIndex <
        formik.errors.distributorSubscriptionDomains[index].subscriptionPlan
          .length &&
      formik.errors.distributorSubscriptionDomains[index]?.subscriptionPlan[
        subIndex
      ]?.effectiveToDate) ||
    (effToDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}
/*istanbul ignore next */
function disableEToDate(isEditMode, subItem, item) {
  return (
    !isEditMode ||
    (subItem.distributorSubscriptionPlanId > 0 &&
      subItem.savedEffectiveToDate) ||
    item.subscriptionPlan.find(
      (x) =>
        !x.distributorSubscriptionPlanId > 0 &&
        subItem.distributorSubscriptionPlanId > 0 &&
        x.frequency === subItem.frequency
    ) ||
    !subItem.effectiveFromDate
  );
}
/*istanbul ignore next */
function getEFromDateValidationMessage(
  formik,
  index,
  subIndex,
  effFromDateRangeExists
) {
  return (
    (formik.errors?.distributorSubscriptionDomains?.length > 0 &&
      index < formik.errors.distributorSubscriptionDomains?.length &&
      formik.errors.distributorSubscriptionDomains[index]?.subscriptionPlan
        ?.length > 0 &&
      subIndex <
        formik.errors.distributorSubscriptionDomains[index].subscriptionPlan
          .length &&
      formik.errors.distributorSubscriptionDomains[index]?.subscriptionPlan[
        subIndex
      ]?.effectiveFromDate) ||
    (effFromDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}
/*istanbul ignore next */
function getFrequencyOptions(frequencyOptions, item, subItem) {
  return frequencyOptions
    ?.filter((x) => {
      let filteredValues = item.subscriptionPlan?.filter(
        (y) =>
          y.frequency === x.value &&
          y.uniqueId !== subItem.uniqueId &&
          (!y.effectiveToDate ||
            moment(y.effectiveToDate) > moment(new Date().setHours(0, 0, 0, 0)))
      );
      return (
        subItem.distributorSubscriptionPlanId > 0 ||
        (filteredValues.length < 2 &&
          filteredValues.filter((x) => !x.distributorSubscriptionPlanId > 0)
            ?.length < 1)
      );
    })
    ?.map((res) => {
      return (
        <option key={res.value} value={res.value}>
          {res.label}
        </option>
      );
    });
}
/*istanbul ignore next */
function baseValueValidation(formik, index, subIndex) {
  return formik.errors &&
    formik.errors.distributorSubscriptionDomains &&
    formik.errors.distributorSubscriptionDomains[index] &&
    formik.errors.distributorSubscriptionDomains[index].subscriptionPlan &&
    formik.errors.distributorSubscriptionDomains[index].subscriptionPlan[
      subIndex
    ]
    ? formik.errors.distributorSubscriptionDomains[index].subscriptionPlan[
        subIndex
      ].baseValue
    : "";
}
/*istanbul ignore next */
function setBaseValue(formik, index, subIndex, e, page) {
  formik.setFieldValue(
    `distributorSubscriptionDomains[${index}].subscriptionPlan[${subIndex}].baseValue`,
    Number(e) || null
  );
  page.setDirty(true);
}
/*istanbul ignore next */
function subPlanValidation(filterQuery, subItem) {
  return (
    (filterQuery.frequency.includes(subItem.frequency) &&
      filterQuery.status.includes(subItem.status)) ||
    !subItem.distributorSubscriptionPlanId > 0
  );
}
/*istanbul ignore next */
function effectiveDateRange(subItem, minFromDate) {
  let effFromDateRangeExists =
    !subItem.distributorSubscriptionPlanId > 0 &&
    subItem.effectiveFromDate &&
    new Date(subItem.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );
  let effToDateRangeExists =
    !subItem.distributorSubscriptionPlanId > 0 &&
    subItem.effectiveToDate &&
    new Date(subItem.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );
  return { effFromDateRangeExists, effToDateRangeExists };
}
/*istanbul ignore next */
function addButtonIndex(filterQuery, x) {
  return (
    (filterQuery.frequency.includes(x.frequency) &&
      filterQuery.status.includes(x.status)) ||
    !x.distributorSubscriptionPlanId > 0
  );
}
/*istanbul ignore next */
function NameValidationMessage(formik, index) {
  return formik.errors &&
    formik.errors.distributorSubscriptionDomains &&
    formik.errors.distributorSubscriptionDomains[index]
    ? formik.errors.distributorSubscriptionDomains[index].name
    : "";
}
/*istanbul ignore next */
function applyFilterOnPlan(item, filterQuery) {
  return item.subscriptionPlan?.filter((x) => addButtonIndex(filterQuery, x));
}
/*istanbul ignore next */
function reversePayloadSubs(payload) {
  payload.distributorSubscriptionDomains?.forEach((element) => {
    element.subscriptionPlan = element.subscriptionPlan?.reverse();
  });
  payload.distributorSubscriptionDomains =
    payload.distributorSubscriptionDomains?.reverse();
}
/*istanbul ignore next */
function getMinFromAndToDate(item, subItem) {
  let minFromDate = new Date(
    Math.max(
      null,
      ...item.subscriptionPlan
        ?.filter(
          (x) =>
            x.frequency === subItem.frequency && x.uniqueId !== subItem.uniqueId
        )
        ?.map((e) => new Date(e.effectiveFromDate)),
      ...item.subscriptionPlan
        ?.filter(
          (x) =>
            x.frequency === subItem.frequency && x.uniqueId !== subItem.uniqueId
        )
        ?.map((e) => new Date(e.effectiveToDate))
    )
  );
  let minToDate = new Date(subItem.effectiveFromDate);
  minFromDate.setDate(minFromDate.getDate() + 1);
  minToDate.setDate(minToDate.getDate());
  return { minFromDate, minToDate };
}
/*istanbul ignore next */
function validateSubscriptions(distributorSubscriptionDomains) {
  let isDuplicate = false;
  if (
    distributorSubscriptionDomains &&
    distributorSubscriptionDomains.length > 0
  ) {
    let valueArr = distributorSubscriptionDomains.map(function (item) {
      return item.name?.trim()?.toLowerCase();
    });
    isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
  }
  return isDuplicate;
}
/*istanbul ignore next */
function forNormalSubPlanFields(field, formik, index, subIndex, e) {
  if (field === "frequency") {
    formik.setFieldValue(
      `distributorSubscriptionDomains[${index}].subscriptionPlan[${subIndex}].${field}`,
      Number(e.target.value)
    );
  } else {
    formik.setFieldValue(
      `distributorSubscriptionDomains[${index}].subscriptionPlan[${subIndex}].${field}`,
      e.target.value
    );
  }
}
/*istanbul ignore next */
function loadBasidDetails(basicDetailPermission, props) {
  return (
    basicDetailPermission.includes(PermissionEnum.LIST) ||
    props?.routeData?.userTypeId === 7
  );
}
/*istanbul ignore next */
function primaryEmailAdmin(
  auth,
  isEditMode,
  editMode,
  formik,
  handleEditClick
) {
  return auth.getRole() === SystemUsersEnum.ADMIN ||
    auth.getRole() === SystemUsersEnum.SUPERADMIN ? (
    <Grid item xs={4} p={2} className="blue-field-section">
      <label htmlFor="primaryEmail">Primary Email Address</label>
      <MandatoryField />
      {isEditMode && !editMode && formik.values.primaryEmail !== "" && (
        <span onClick={handleEditClick}>
          <CreateOutlinedIcon className="cus-height-createOutlinedIcon" />
        </span>
      )}

      {editModePrimaryEmail(formik, editMode, isEditMode)}
    </Grid>
  ) : (
    <Grid item xs={4} p={2} className="blue-field-section">
      <label htmlFor="primaryEmail">Primary Email Address</label>
      <MandatoryField />
      {formik.values.id ? (
        <a
          className="primary-email-url"
          href={`mailto:${formik.values.primaryEmail}`}
        >
          {formik.values.primaryEmail}
        </a>
      ) : (
        <>
          <input
            id="primaryEmail"
            name="primaryEmail"
            value={formik.values.primaryEmail}
            onChange={formik.handleChange}
            className="form-label form-control"
            type="text"
            placeholder="Enter Primary Email Address"
          />
          <InlineMessage error={formik.errors.primaryEmail} />
        </>
      )}
    </Grid>
  );
}

/*istanbul ignore next */
function editModePrimaryEmail(formik, editMode, isEditMode) {
  return formik.values.id ? (
    <>
      {editMode ? (
        <div>
          <input
            id="primaryEmail"
            name="primaryEmail"
            value={formik.values.primaryEmail}
            onChange={formik.handleChange}
            className="form-label form-control"
            type="text"
            placeholder="Enter Primary Email Address"
            disabled={!isEditMode}
          />
          <InlineMessage error={formik.errors.primaryEmail} />
        </div>
      ) : (
        <a
          className="primary-email-url"
          href={`mailto:${formik.values.primaryEmail}`}
        >
          {formik.values.primaryEmail}
        </a>
      )}
    </>
  ) : (
    <>
      <input
        id="primaryEmail"
        name="primaryEmail"
        value={formik.values.primaryEmail}
        onChange={formik.handleChange}
        className="form-label form-control"
        type="text"
        placeholder="Enter Primary Email Address"
        disabled={!isEditMode}
      />
      <InlineMessage error={formik.errors.primaryEmail} />
    </>
  );
}

/*istanbul ignore next */
function getLogoText(logo, formik) {
  return logo || formik.values.logoGcpPath ? (
    <span className="filename">Replace File</span>
  ) : (
    <span className="filename">Choose File</span>
  );
}

/*istanbul ignore next */
function formattingPayload(payload, props, auth, currentUserId) {
  if (
    props?.routeData?.userTypeId === 7 &&
    auth.getRole() === SystemUsersEnum.ENTERPRISE
  ) {
    if (!payload.enterpriseIds?.find((x) => x === currentUserId)) {
      payload.enterpriseIds = [...payload.enterpriseIds, currentUserId];
    }
  } else if (
    props?.routeData?.userTypeId === 7 &&
    auth.getRole() === SystemUsersEnum.DISTRIBUTOR
  ) {
    if (!payload.distributorIds?.find((x) => x === currentUserId)) {
      payload.distributorIds = [...payload.distributorIds, currentUserId];
    }
  }
}
