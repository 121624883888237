import { env } from "../env";

const BASE_API_URL = env.REACT_APP_API_ENDPOINT;

export const LOGIN_URL = `${BASE_API_URL}Login`;
export const LOGOUT = `${BASE_API_URL}Logout`;
export const VERIFY2FA = `${LOGIN_URL}/Verify2FA`;
export const VALIDATE_EMAIL = `${BASE_API_URL}Account/Reset`;
export const RESET_PASSWORD = `${BASE_API_URL}Account/Reset/Verify`;
export const VALIDATE_ACTIVATION_CODE = `${BASE_API_URL}Account/Activation/ValidateCode`;
export const ACTIVATE_ACCOUNT = `${BASE_API_URL}Account/Activation/Activate`;
export const UNBLOCK_USER = `${BASE_API_URL}Admin/User/Unlock`;
export const UPLOAD_LOGO = `${BASE_API_URL}Account/upload/logo`;
export const MODULES_PERMISSION_API = `${BASE_API_URL}Dashboard/Navigation`;
export const MASTER_DATA = `${BASE_API_URL}MasterData`;
export const MASTER_SETTINGS = `${BASE_API_URL}MasterData/Setting`;
export const UPDATE_SETTINGS = `${BASE_API_URL}settings`;

export const ADMIN = `${BASE_API_URL}Admin/`;
export const ADMIN_EXPORT = `${ADMIN}export`;
export const DISTRIBUTOR = `${BASE_API_URL}distributors`;
export const ADMIN_USER = `${ADMIN}User`;
export const ADMIN_USERS = `${ADMIN}Users`;
export const ADMIN_CHANGE_STATUS = `${ADMIN}changeStatus`;
export const ADD_DISTRIBUTOR = `${BASE_API_URL}distributor`;
export const DISTRIBUTOR_EXPORT = `${ADD_DISTRIBUTOR}/export`;
export const INTERNAL_USERS = `${BASE_API_URL}internalusers`;
export const ADD_EDIT_INTERNAL_USERS = `${BASE_API_URL}internaluser`;
export const INTERNAL_USERS_EXPORT = `${ADD_EDIT_INTERNAL_USERS}/export`;
export const INTERNAL_USER_CHANGE_STATUS = `${BASE_API_URL}internaluser/ChangeStatus`;
export const ENTERPRISE_GROUP_USERS = `${BASE_API_URL}enterprises`;
export const ENTERPRISE_GROUP_USER = `${BASE_API_URL}enterprise`;
export const ENTERPRISE_GROUP_USER_RENEW = `${BASE_API_URL}enterprise/asociation/renew`;
export const ENTERPRISE_GROUP_EXPORT = `${ENTERPRISE_GROUP_USER}/export`;

export const GET_ASSOCIATE_DISTRIBITORS = `${ENTERPRISE_GROUP_USER}/distributors`;
export const SUBSCRIPTION_TYPE = `${ENTERPRISE_GROUP_USER}/distributorSubscriptions`;
export const GET_SUBSCRIPTION_TYPE = `${ENTERPRISE_GROUP_USER}/distributorSubscription/`;
export const SEND_ASSOCIATE_DIST_APPROVAL = `${ENTERPRISE_GROUP_USER}/associate/distributer`;
export const BUY_SUBSCRIPTION_ENTERPRISE = `${ENTERPRISE_GROUP_USER}/associate/new`;

export const DISTRIBUTOR_INTERNAL_USER_BULK_UPLOAD = `${BASE_API_URL}internaluser/bulkupload`;
export const SUBSCRIBERS = `${BASE_API_URL}subscribers`;
export const SUBSCRIBER = `${BASE_API_URL}subscriber`;
export const ENTERPRISE = `${BASE_API_URL}enterprise/enterprises`;
export const DISTRIBUTORS = `${BASE_API_URL}distributor/distributors`;
export const SIGNUP = `${BASE_API_URL}Account/User/SignUp`;
export const SUBSCRIBER_EXPORT = `${SUBSCRIBER}/export`;
export const GET_ED_USERS = `${BASE_API_URL}Admin/GetEDUser`;

export const CURRENT_USER = `${BASE_API_URL}auth`;
export const CONTENT = `${BASE_API_URL}content`;
export const CONTENT_EXPORT = `${CONTENT}/export`;
export const CONTENT_FAVORITE = `${CONTENT}/favorite`;
export const CONTENT_STATUS = `${CONTENT}/changestatus`;

export const CATALOG = `${BASE_API_URL}catalog`;
export const CATALOG_EXPORT = `${CATALOG}/export`;
export const CATALOG_FAVORITE = `${CATALOG}/Favorite`;
export const CATALOG_STATUS = `${CATALOG}/ChangeStatus`;
export const CATALOG_CONTENT = `${CATALOG}/content`;
export const CATALOG_ASSOCIATED_CONTENT = `${CATALOG}/associated/content`;
export const CATALOG_ASSOCIATED_CONTENT_EXPORT = `${CATALOG}/associated/content/export`;

export const BUNDLE = `${BASE_API_URL}bundle`;
export const BUNDLE_EXPORT = `${BUNDLE}/export`;
export const BUNDLE_FAVORITE = `${BUNDLE}/Favorite`;
export const BUNDLE_STATUS = `${BUNDLE}/ChangeStatus`;
export const BUNDLE_ASSOCIATED_CONTENT = `${BUNDLE}/associated/content`;
export const BUNDLE_ASSOCIATED_CONTENT_EXPORT = `${BUNDLE}/associated/content/export`;

export const SUBSCRIBER_BULK_UPLOAD = `${BASE_API_URL}subscriber/bulkupload`;

export const ROLE_LIST = `${BASE_API_URL}role`;

export const FEATURE_LIST = `${BASE_API_URL}role/feature`;

export const ADD_ROLE = `${BASE_API_URL}role`;

export const ROLE_DETAILS = `${BASE_API_URL}role/id/`;

export const UPDATE_ROLE = `${BASE_API_URL}role`;

export const MANAGE_SUBSCRIBER_LIST = `${BASE_API_URL}content/subscribers`;

export const MANAGE_SUBSCRIBER_LIST_CHANGE_STATUS = `${BASE_API_URL}content/subscriber/ChangeStatus`;

export const SUBSCRIBER_LIST_ID = `${BASE_API_URL}subscribers/list`;
export const SUBSCRIBER_ADD_ON = `${BASE_API_URL}useraddon`;
export const ENTERPRISE_SUBSCRIPTION = `${BASE_API_URL}subscriber/Subscription`;
export const SUBSCRIBER_RENEWAL_HISTORY = `${BASE_API_URL}subscriber/GetSubscriptionHistory`;

export const ASSOCIATE_SUBSCRIBER = `${BASE_API_URL}content/subscriber`;

export const EXPORT_ASSOCIATE_SUBSCRIBER = `${BASE_API_URL}content/subscriber/export`;

export const EXPORT_ROLE = `${BASE_API_URL}role/export`;

export const PARENT_DISTRIBUTOR = `${BASE_API_URL}distributor/distributors`;

export const APPROVAL_LIST = `${BASE_API_URL}approval`;
export const UPDATE_APPROVAL = `${BASE_API_URL}approval/action`;
export const UPDATE_SIGNUP = `${BASE_API_URL}approval/signup/action`;

export const ENT_DIST_SUBSCRIPTION = `${BASE_API_URL}enterprise`;
export const PARENT_ENTERPRISE = `${BASE_API_URL}enterprise/enterprises`;
export const DISTRIBUTOR_SUBSCROPTIONTYPES = `${ENT_DIST_SUBSCRIPTION}/distributorSubscription`;

export const BULK_UPLOAD_CONTENT_SUBSCRIBERS = `${CONTENT}/subscriber/BulkUpload`;
export const MASS_UPLOAD_CONTENT = `${CONTENT}/BulkUpload`;
export const MASS_UPLOAD_CONTENT_DOWNLOAD_PROCESSED_REQUEST = `${CONTENT}/BulkUpload/DownloadProcessedRequest`;

export const CATALOG_LIST_CONTENT_CHANGE_STATUS = `${BASE_API_URL}catalog/associatecontent/ChangeStatus`;

export const UPLOAD_DOCUMENT_URL = `${BASE_API_URL}document`;
export const ERROR_DOCUMENT_URL = `${BASE_API_URL}document/error`;

export const SHARE_VALIDATE = `${BASE_API_URL}share/validate`;
export const SHARE_VALIDATE_PASSWORD = `${SHARE_VALIDATE}/password`;
export const SHARE_ACTION = `${BASE_API_URL}Share/Action`;
export const SHARE_Download_XFDL_TO_PDF = `${BASE_API_URL}Share/Download/XfdlToPdf`;
export const STOP_SUBSCRIPTION = `${BASE_API_URL}enterprise/association/stop`;
export const RENEWAL_HISTORY = `${BASE_API_URL}enterprise/association/history`;
export const SUBCRIPTION_DETAILS = `${BASE_API_URL}enterprise/distributor/Subscription/Association`;
export const APPROVAL_ENTERPRISE_SUBSCRIBERS = `${BASE_API_URL}approval/enterprise/Subscription/Association`;
export const ACTION_STATUS = `${BASE_API_URL}approval/subscriber/Subscription/action`;
export const ASSOCIATE_CONTENT = `${BASE_API_URL}approval/enterprise/content/subscription/action`;
