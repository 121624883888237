import React from "react";
import { TextField } from "@mui/material";
import { ARGB_To_RGBA_Color } from "../constants";

function TextBox(props) {
  const { item, defaultValue, isRequired, fType } = props;

  const textFieldStyle = {
    position: "absolute",
    top: `${item.SetTop + 3}px`,
    left: props.isBgImage ? `${item.SetLeft}px` : `${item.SetLeft + 10}px`,
    borderRadius: 0,
  };

  const whenNotReq = defaultValue ? "1px solid black" : "2px solid blue";
  const checkNonAcordColor =
    fType === "NonAcord"
      ? ARGB_To_RGBA_Color(item.Foreground)
      : item.Foreground;
  const getColor = item.Foreground ? checkNonAcordColor : "black";
  return (
    <TextField
      type="text"
      className="share-text-box-xfdl"
      title={item.ToolTip?.Content || ""}
      name={item.Name}
      value={defaultValue}
      id={item.Name}
      placeholder={item.Name.includes("Date") ? "MM/DD/YYYY" : ""}
      onChange={(e) => {
        props.changeHandler(e, item.Name, isRequired);
      }}
      variant="outlined"
      InputProps={{
        inputProps: {
          style: {
            padding: "0 2px",
            fontFamily: item.FontFamily ? item.FontFamily : "Arial",
            fontSize: item.FontSize ? item.FontSize : 8,
            fontStyle: item.FontStyle,
            fontWeight: item.FontWeight ? item.FontWeight : 600,
            color: getColor,
            textAlign: item.TextAlignment,
            width: `${item.Width - 2}px`,
            height: fType === "NonAcord" ? item.Height : null,
            // height:
            //   item.Name === "Form_EditionIdentifier_A" ? 20 : item.Height - 6,
            backgroundColor: "white",
            borderRadius: 0,
            border: isRequired ? "2px solid red" : whenNotReq,
          },
        },
      }}
      style={textFieldStyle}
    />
  );
}

export default TextBox;
