import { MenuListItems } from "../common/Sidebar/MenuListItems";
import { SystemModulesEnum, SystemUsersEnum } from "./SystemUsers";

const getSingleModule = (moduleId) => {
  const modules = JSON.parse(localStorage.getItem("modulePermissions")) || [];
  return modules.find((item) => item.moduleId === moduleId);
};

//for single parent module permission list.
export const PermissionService = (moduleId) => {
  const singleModule = getSingleModule(moduleId);
  const permission =
    singleModule?.features?.map((item) => item.featureName) || [];
  return permission;
};

export const PermissionServiceChild = (moduleId, childModuleName) => {
  const parentModule = getSingleModule(moduleId);
  const childModule = parentModule?.childMenu?.find(
    (item) => item.name === childModuleName
  );
  const permission =
    childModule?.features?.map((item) => item.featureName) || [];
  return permission;
};

export const childModulePermissions = (moduleId, childModuleName) => {
  const parentModule = getSingleModule(moduleId);
  const childModule = parentModule?.subMenu?.find(
    (item) => item.name === childModuleName
  );
  const perm = childModule?.features?.map((el) => el.featureName) || [];
  return perm;
};

export const enabledTabs = (moduleId) => {
  const parentModule = getSingleModule(moduleId);
  if (moduleId === 5) {
    return ["Basic Information"];
  }
  let tabs = parentModule?.subMenu?.map((el) => el.name) || [];
  if (
    moduleId === 2 &&
    (tabs.includes("Distributor Basic Details") ||
      tabs.includes("Distributor Association Details") ||
      tabs.includes("Distributor Subscription Details"))
  ) {
    tabs = ["Basic Information", ...tabs];
  }
  return tabs;
};

export const getModuleId = (userTypeId) => {
  let moduleName = "";

  switch (userTypeId) {
    case SystemUsersEnum.ADMIN:
    case SystemUsersEnum.SUPERADMIN:
      moduleName = SystemModulesEnum.SUPER_ADMIN;
      break;
    case SystemUsersEnum.DISTRIBUTOR:
      moduleName = SystemModulesEnum.DISTRIBUTORS;
      break;
    case SystemUsersEnum.ENTERPRISE:
      moduleName = SystemModulesEnum.ENTERPRISE_GROUPS;
      break;
    case SystemUsersEnum.SUBSCRIBER:
      moduleName = SystemModulesEnum.SUBSCRIBERS;
      break;
    default:
      break;
  }

  switch (moduleName) {
    case SystemModulesEnum.SUPER_ADMIN:
    case SystemModulesEnum.ADMIN:
      return MenuListItems.find((item) => item.name === "Users").moduleId;
    case SystemModulesEnum.DISTRIBUTORS:
      return MenuListItems.find((item) => item.name === "Distributors")
        .moduleId;
    case SystemModulesEnum.ENTERPRISE_GROUPS:
      return MenuListItems.find((item) => item.name === "Enterprise Groups")
        .moduleId;
    case SystemModulesEnum.SUBSCRIBERS:
      return MenuListItems.find((item) => item.name === "Subscribers").moduleId;
    default:
      return null;
  }
};

export const PermissionEnum = {
  LIST: "List",
  CREATE: "Create",
  UPDATE: "Update",
};
