import React from "react";
import { Grid, Typography, Breadcrumbs, Box, Tooltip } from "@mui/material";
import { formatDate } from "../../utilities/formatter";
import "../BasicInformation/BasicInfo.css";
import { toast } from "react-toastify";
import { BreadCrumbRoutes } from "../../screens/Distributors/constants";
import { Link, useHistory } from "react-router-dom";
import { usePageMode } from "../../hooks/usePageMode";
import Filter from "../../common/Filter/Filter";
import { MODE, TABS } from "../../constants/common";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import UploadUserModal from "../../common/Modal/UploadUserModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { PermissionEnum } from "../../constants/permissionService";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import { useAuth } from "../../hooks/useAuth";

export const BasicInfo = (props) => {
  const {
    selectedSubTab,
    selectedTab,
    anchorEl,
    id,
    open,
    internalFilterOptions,
    applyInternalFilterHandler,
    applytypecontentFilterHandler,
    typecontentListOptions,
    handleClose,
    setCreateUserView,
    createUserView,
    handleClick,
    setBundleId,
    handleExport,
    bulkUploadHandler,
    bulkUploadTemplate,
    contentQuery,
    location,
    setContentId,
    setCatalogId,
    setTableColumnModalVisibility,
    setTableColumnModalVisibilityInternal,
    tabPermissions,
    formData,
    onCopyHandler,
    allUsersExportPermission,
    applyContentFilterHandler,
    contentFilterOptions,
    catalogFilterOptions,
    bundleFilterOptions,
    contentMode,
    setContentMode,
    contentContext,
    catalogContext,
    bundleContext,
    catalogMode,
    setCatalogMode,
    bundleMode,
    setBundleMode,
    currentUser,
  } = props;
  const page = usePageMode();
  const history = useHistory();
  const auth = useAuth();
  return (
    <>
      <Grid container item xs={12} className="fixed-comp">
        <Grid item xs={12} className="breadcrumbs ">
          <Box pt={2} pr={4} pl={4} pb={0} className="breadcrumbs-cus-pad">
            {/* <h5>{props.routeData?.title}</h5> */}
            <div className="cus-cus-pad-pad">
              <div className="custom-bredscrum-width">
                <Breadcrumbs
                  separator="›"
                  aria-label="breadcrumb"
                  className="distributor-breadcrumbs"
                >
                  {page.breadcrumbRootNode?.title && (
                    <Link
                      className="breadcrumb-title"
                      underline="hover"
                      to={`${page.breadcrumbRootNode?.path}`}
                    >
                      {page.breadcrumbRootNode?.title}
                    </Link>
                  )}

                  {page.breadcrumbRoute?.map((el, i) => {
                    const last = i === page.breadcrumbRoute.length - 1;
                    const to = `/${page.breadcrumbRoute
                      .slice(0, i + 1)
                      .join("/")}`;
                    const notLastSection = !BreadCrumbRoutes[el] ? null : (
                      <Link
                        className="breadcrumb-title"
                        underline="hover"
                        color="inherit"
                        to={`${to}`}
                        key={`${to}`}
                      >
                        <Typography color="text.primary">
                          {BreadCrumbRoutes[el]}
                        </Typography>
                      </Link>
                    );
                    return last ? (
                      <Typography
                        color="text.primary"
                        sx={{ fontWeight: "bold" }}
                        className="content-last"
                      >
                        {BreadCrumbRoutes[el]}
                      </Typography>
                    ) : (
                      notLastSection
                    );
                  })}
                </Breadcrumbs>
              </div>
              {/* <div className="toast-cus-toast-top">
                <ToastContainer
                  closeButton={true}
                  position="top-right"
                  className="toast-cus-top"
                />
              </div> */}
            </div>

            <div className="cus-brandname-grid">
              {formData?.logoGcpFileUrl && (
                <Grid item className="fixed-comp">
                  <div className="detail-content brand-name">
                    <img
                      className="brand-logo"
                      src={formData?.logoGcpFileUrl}
                      alt=""
                    />
                  </div>
                </Grid>
              )}

              {(window.location.pathname.includes("distributor") ||
                window.location.pathname.includes("enterprisegroup")) && (
                <Grid item xs={4} md={2} lg={2} className="fixed-comp">
                  <div className="card-item-label">Brand Name</div>
                  <div
                    className="detail-content brand-name"
                    title={formData?.brandName || "NA"}
                  >
                    {formData?.brandName || "NA"}
                  </div>
                </Grid>
              )}

              <Grid item xs={4} md={2} lg={2} className="fixed-comp">
                <div className="card-item-label">Full Name</div>
                <div
                  className="detail-content"
                  title={`${formData?.firstName} ${formData?.lastName}`}
                >
                  {formData?.firstName} {formData?.lastName}
                </div>
              </Grid>
              <Grid item xs={4} md={3} lg={3} className="fixed-comp">
                <div className="card-item-label">Email Address</div>
                <div
                  className="detail-content-email"
                  title={formData?.primaryEmail || "xxxxxx@xxxxx.com"}
                >
                  {formData?.primaryEmail || "xxxxxx@xxxxx.com"}
                </div>
              </Grid>
              <Grid item xs={4} md={2} lg={2} className="fixed-comp">
                <div className="card-item-label">System Generated ID</div>
                <div
                  className="detail-content"
                  title={formData?.systemGeneratedId || "xxxxxx"}
                >
                  {formData?.systemGeneratedId || "xxxxxx"}
                </div>
              </Grid>
              <Grid item xs={4} md={2} lg={2} className="fixed-comp">
                <div className="card-item-label">Registration Date</div>
                <div
                  className="detail-content "
                  title={
                    formData?.createdOn
                      ? formatDate(formData?.createdOn)
                      : "Not Created"
                  }
                >
                  {formData?.createdOn
                    ? formatDate(formData?.createdOn)
                    : "Not Created"}
                </div>
              </Grid>
            </div>
            <Grid item xs={12}>
              <Box pr={4} pl={4} mb={2}>
                {selectedSubTab !== TABS.ENROLLED_USERS && (
                  <div className="add-welcome-widgets-breadcrumb">
                    {/* Create Internal User Icon */}
                    {tabPermissions?.includes(PermissionEnum.CREATE) &&
                      selectedTab === TABS.INTERNAL_USERS && (
                        <Tooltip placement="bottom" title="Create">
                          <AddOutlinedIcon
                            name="add-icon"
                            title="add-icon"
                            onClick={() => {
                              setCreateUserView(!createUserView);
                              if (!id) {
                                toast.error(props?.routeData?.errorMessage);
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    {/* Content Create Icon */}
                    {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
                      !history.location.pathname.includes("enterprisegroup") &&
                      tabPermissions?.includes(PermissionEnum.CREATE) &&
                      selectedTab === TABS.CONTENT &&
                      contentMode === MODE.LIST && (
                        <Tooltip placement="bottom" title="Create">
                          <AddOutlinedIcon
                            name="add-icon"
                            title="add-icon"
                            onClick={() => {
                              contentContext.setContentMode(MODE.ADD);
                              contentContext.setSelectedTab(
                                TABS.CONTENT_INFORMATION
                              );
                              setContentId(0);
                              setContentMode(MODE.ADD);
                              contentContext.setContentId(0);
                              setContentId(0);
                              history.push(
                                `${location.pathname}/${TABS.CONTENT_INFORMATION}`
                              );
                            }}
                          />
                        </Tooltip>
                      )}
                    {/* Catalog Create Icon */}
                    {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
                      !history.location.pathname.includes("enterprisegroup") &&
                      tabPermissions?.includes(PermissionEnum.CREATE) &&
                      selectedTab === TABS.CATALOG &&
                      catalogMode === MODE.LIST && (
                        <Tooltip placement="bottom" title="Create">
                          <AddOutlinedIcon
                            name="add-icon"
                            title="add-icon"
                            onClick={() => {
                              catalogContext.setCatalogMode(MODE.ADD);
                              catalogContext.setSelectedTab(
                                TABS.CATALOG_INFORMATION
                              );
                              setCatalogId(0);
                              setCatalogMode(MODE.ADD);
                              catalogContext.setCatalogId(0);
                              setCatalogId(0);
                              history.push(
                                `${location.pathname}/${TABS.CATALOG_INFORMATION}`
                              );
                            }}
                          />
                        </Tooltip>
                      )}
                    {/* Bundle Create Icon */}
                    {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
                      !history.location.pathname.includes("enterprisegroup") &&
                      tabPermissions?.includes(PermissionEnum.CREATE) &&
                      selectedTab === TABS.BUNDLE &&
                      bundleMode === MODE.LIST && (
                        <Tooltip placement="bottom" title="Create">
                          <AddOutlinedIcon
                            name="add-icon"
                            title="add-icon"
                            onClick={() => {
                              bundleContext.setBundleMode(MODE.ADD);
                              bundleContext.setSelectedTab(
                                TABS.BUNDLE_INFORMATION
                              );
                              setBundleId(0);
                              setBundleMode(MODE.ADD);
                              bundleContext.setBundleId(0);
                              setBundleId(0);
                              history.push(
                                `${location.pathname}/${TABS.BUNDLE_INFORMATION}`
                              );
                            }}
                          />
                        </Tooltip>
                      )}

                    {selectedTab === TABS.INTERNAL_USERS && (
                      <Filter
                        selectedTab={selectedTab}
                        anchorEl={anchorEl}
                        id={id}
                        open={open}
                        options={internalFilterOptions}
                        onApplyFilter={applyInternalFilterHandler}
                        onClose={handleClose}
                      />
                    )}
                    {selectedTab === TABS.CONTENT && (
                      <Filter
                        anchorEl={anchorEl}
                        id={id}
                        open={open}
                        options={contentFilterOptions}
                        onApplyFilter={applyContentFilterHandler}
                        onClose={handleClose}
                      />
                    )}
                    {selectedTab === TABS.CATALOG &&
                      selectedSubTab !== TABS.CONTENT_LIST && (
                        <Filter
                          anchorEl={anchorEl}
                          id={id}
                          open={open}
                          options={catalogFilterOptions}
                          onApplyFilter={applyContentFilterHandler}
                          onClose={handleClose}
                        />
                      )}
                    {selectedTab === TABS.BUNDLE && (
                      <Filter
                        anchorEl={anchorEl}
                        id={id}
                        open={open}
                        options={bundleFilterOptions}
                        onApplyFilter={applyContentFilterHandler}
                        onClose={handleClose}
                      />
                    )}

                    {selectedTab === TABS.CATALOG &&
                      selectedSubTab === TABS.CONTENT_LIST && (
                        <Filter
                          anchorEl={anchorEl}
                          id={id}
                          open={open}
                          options={typecontentListOptions}
                          onApplyFilter={applytypecontentFilterHandler}
                          onClose={handleClose}
                        />
                      )}

                    {selectedTab === TABS.INTERNAL_USERS && (
                      <Tooltip placement="bottom" title="Filter">
                        <FilterAltOutlinedIcon
                          onClick={handleClick}
                          title="filter-icon"
                        />
                      </Tooltip>
                    )}

                    {selectedTab === TABS.CONTENT &&
                      selectedSubTab !== TABS.CONTENT_INFORMATION &&
                      selectedSubTab !== TABS.CONTENT_PREVIEW && (
                        <Tooltip placement="bottom" title="Filter">
                          <FilterAltOutlinedIcon
                            onClick={handleClick}
                            title="filter-icon"
                          />
                        </Tooltip>
                      )}
                    {selectedTab === TABS.CATALOG &&
                      selectedSubTab !== TABS.CATALOG_INFORMATION && (
                        <Tooltip placement="bottom" title="Filter">
                          <FilterAltOutlinedIcon
                            onClick={handleClick}
                            title="filter-icon"
                          />
                        </Tooltip>
                      )}

                    {selectedTab === TABS.BUNDLE &&
                      selectedSubTab !== TABS.BUNDLE_INFORMATION && (
                        <Tooltip placement="bottom" title="Filter">
                          <FilterAltOutlinedIcon
                            onClick={handleClick}
                            title="filter-icon"
                          />
                        </Tooltip>
                      )}

                    {contentSection()}
                    {catalogSection()}
                    {bundleSection()}

                    {(selectedTab === TABS.BASIC &&
                      (currentUser?.userTypeId === 0 ||
                        currentUser?.userTypeId === 1)) ||
                      (window.location.pathname === props.routeData && (
                        <Tooltip placement="bottom" title="Create">
                          <AddOutlinedIcon
                            name="add-icon"
                            onClick={() => {
                              /*istanbul ignore next */
                              page.setEditMode(true);
                              if (selectedTab === TABS.CONTENT) {
                                setContentId(0);
                                setContentMode(MODE.ADD);
                              } else {
                                history.push("/distributor");
                              }
                            }}
                          />
                        </Tooltip>
                      ))}

                    {(selectedTab === TABS.CONTENT ||
                      selectedTab === TABS.CATALOG ||
                      selectedTab === TABS.BUNDLE) &&
                      !selectedSubTab && (
                        <Tooltip placement="bottom" title="Change View">
                          <BorderColorIcon
                            data-testid="TableColumnSelectionIcon"
                            onClick={() => {
                              setTableColumnModalVisibility(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    {selectedSubTab === TABS.CONTENT_LIST && (
                      <Tooltip placement="bottom" title="Change View">
                        <BorderColorIcon
                          data-testid="TableColumnSelectionIcon"
                          onClick={() => {
                            setTableColumnModalVisibility(true);
                          }}
                        />
                      </Tooltip>
                    )}
                    {selectedTab === TABS.INTERNAL_USERS && (
                      <Tooltip placement="bottom" title="Change View">
                        <BorderColorIcon
                          data-testid="TableColumnSelectionIcon"
                          onClick={() => {
                            setTableColumnModalVisibilityInternal(true);
                          }}
                        />
                      </Tooltip>
                    )}

                    {allUsersExportPermission &&
                      selectedTab === TABS.INTERNAL_USERS && (
                        <Tooltip placement="bottom" title="Export to Excel">
                          <IosShareRoundedIcon
                            data-testid="IosShareRoundedIcon"
                            onClick={handleExport}
                          />
                        </Tooltip>
                      )}

                    {allUsersExportPermission &&
                      selectedTab === TABS.CONTENT &&
                      selectedSubTab !== TABS.CONTENT_INFORMATION &&
                      selectedSubTab !== TABS.CONTENT_PREVIEW && (
                        <Tooltip placement="bottom" title="Export to Excel">
                          <IosShareRoundedIcon
                            data-testid="IosShareRoundedIcon"
                            onClick={handleExport}
                          />
                        </Tooltip>
                      )}
                    {allUsersExportPermission &&
                      selectedTab === TABS.CATALOG &&
                      selectedSubTab !== TABS.CATALOG_INFORMATION && (
                        <Tooltip placement="bottom" title="Export to Excel">
                          <IosShareRoundedIcon
                            data-testid="IosShareRoundedIcon"
                            onClick={handleExport}
                          />
                        </Tooltip>
                      )}
                    {allUsersExportPermission &&
                      selectedTab === TABS.BUNDLE &&
                      selectedSubTab !== TABS.BUNDLE_INFORMATION && (
                        <Tooltip placement="bottom" title="Export to Excel">
                          <IosShareRoundedIcon
                            data-testid="IosShareRoundedIcon"
                            onClick={handleExport}
                          />
                        </Tooltip>
                      )}
                    {selectedTab === TABS.CONTENT &&
                      !(
                        selectedSubTab === TABS.CONTENT_INFORMATION ||
                        selectedSubTab === TABS.CONTENT_PREVIEW ||
                        selectedSubTab === TABS.ENROLLED_USERS
                      ) && (
                        <UploadUserModal
                          from="content-manage-subscribers"
                          userId={id}
                          title={"Bulk Upload Content Subscribers"}
                          bulkUploadTemplate={
                            "/templates/Content_Subscriber_Bulk_Upload.xlsx"
                          }
                          setRefresh={applyContentFilterHandler}
                          contentQuery={contentQuery}
                          isSelected={page.isContentIdsSelected}
                        />
                      )}
                    {/* Upload Internal User icon */}
                    {tabPermissions?.includes(PermissionEnum.CREATE) &&
                      selectedTab === TABS.INTERNAL_USERS && (
                        <UploadUserModal
                          userId={id}
                          bulkUploadTemplate={bulkUploadTemplate}
                          onBulkUpload={bulkUploadHandler}
                          title={"Bulk Upload Users"}
                        />
                      )}
                  </div>
                )}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  function bundleSection() {
    return (
      selectedTab === TABS.BUNDLE &&
      (bundleContext.bundleMode === MODE.EDIT ||
        bundleContext.bundleMode === MODE.VIEW) && (
        <Tooltip placement="bottom" title="Copy">
          <ContentCopyIcon
            data-testid="copyBundleIcon"
            name="copy-catalog-icon"
            title="copy-catalog-icon"
            onClick={() => {
              onCopyHandler("bundle");
            }}
          />
        </Tooltip>
      )
    );
  }

  function catalogSection() {
    return (
      selectedTab === TABS.CATALOG &&
      (catalogContext.catalogMode === MODE.EDIT ||
        catalogContext.catalogMode === MODE.VIEW) && (
        <Tooltip placement="bottom" title="Copy">
          <ContentCopyIcon
            data-testid="copyCatalogIcon"
            name="copy-catalog-icon"
            title="copy-catalog-icon"
            onClick={() => {
              onCopyHandler("catalog");
            }}
          />
        </Tooltip>
      )
    );
  }

  function contentSection() {
    return (
      selectedTab === TABS.CONTENT &&
      (contentContext.contentMode === MODE.EDIT ||
        contentContext.contentMode === MODE.VIEW) && (
        <Tooltip placement="bottom" title="Copy">
          <ContentCopyIcon
            name="add-icon"
            title="add-icon"
            onClick={() => {
              onCopyHandler("content");
            }}
          />
        </Tooltip>
      )
    );
  }
};

export default BasicInfo;
