import React from "react";
import { ARGB_To_RGBA_Color } from "../constants";

function TextBlock(props) {
  const { item, fType } = props;
  const checkAcordColor =
    fType === "NonAcord"
      ? ARGB_To_RGBA_Color(item.Foreground)
      : item.Foreground;
  const getColor = item.Foreground ? checkAcordColor : "black";
  return (
    <span
      style={{
        width: item.Width ? item.Width : "auto",
        height: item.Height - 5,
        position: "absolute",
        top: item.SetTop + 4,
        left: item.SetLeft + 10,
        fontFamily: item.FontFamily,
        fontSize: item.FontSize,
        fontStyle: item.FontStyle,
        fontWeight: item.FontWeight,
        textAlign: item.TextAlignment,
        textDecoration: item.TextDecorations,
        color: getColor,
      }}
    >
      {item.Text}
    </span>
    // <span style=" width: @item.Width; height: @item.Height;position: absolute;top: @item.SetTop;left: @item.SetLeft;font-size:@fontSize">@item.Text</span>
  );
}

export default TextBlock;
